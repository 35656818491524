<div class="info-container"
	[class.info]="mode === 'info'"
	[class.warning]="mode === 'warning'"
	[class.gray-scale]="mode === 'gray-scale'"
	[class.light-gray]="mode === 'light-gray'"
	[class.green]="mode === 'green'"
	[class.no-icon]="!showIcon"
	[class.no-grid]="onlyContentProjection"
>

	<ng-container *ngIf="!onlyContentProjection">
		<div class="icon-container" *ngIf="showIcon"><i class="far fa-exclamation-circle"></i></div>

		<div class="message-container">
			<ng-content select="[message]"></ng-content>
		</div>
		<div>
			<ng-content select="[actions]"></ng-content>
		</div>
	</ng-container>

	<ng-container *ngIf="onlyContentProjection">
		<ng-content></ng-content>
	</ng-container>
</div>