<div class="bucket" *ngIf="numItems && numItems > 0" [class.no-images]="hideImages">
	<div class="bucket-container">
		<div class="header" (click)="toggleState()">
			<div class="heading d-flex">
				<ng-container *ngIf="!hideImages">
					<div class="image-stack" [class.two-items]="numItems >= 2" [class.more-items]="numItems >= 3">
						<div class="image-container"></div>
						<div class="image-container"></div>
						<div class="image-container"
							width                     = "19"
							height                    = "19"
							[customClBackgroundImage] = "[ CloudinaryPathEnum.Parts + '/' + items?.first?.item?.imageAssetId + '.png', (items?.first?.item?.type === AreaItemTypeEnum.Labor ? CloudinaryPathEnum.LaborPlaceholder : CloudinaryPathEnum.Placeholder) ] | pipeFunction: getProductThumbnailImageUrl"
						></div>
					</div>
				</ng-container>
				<div class="text-nowrap fw-bold" style="margin-top: 1px">{{ numItems | i18nPlural: {'=1': '1 Attachment', 'other': '# Attachments'} }} {{ combinePrice ? 'Included' : '' }} &nbsp;<i class="fa-solid icon" [class.fa-angle-down]="!showItems" [class.fa-angle-up]="showItems"></i></div>
			</div>

			<div class="price last-column"><span *ngIf="!hideItemPrices && !combinePrice" [class.invisible]="hideTotal">{{ totalGroupPrice | currencyFormat:currency?.format:currency?.symbol }}</span></div>
		</div>
		<ng-container *ngIf="showItems">
			<div><ng-content></ng-content></div>
		</ng-container>
	</div>
</div>
