<div class="entity-tile">
	<div class="client-details">
		<div *ngIf="payment?.number" class="entity-number">#{{ payment?.number }}</div>
		<div *ngIf="payment?.amount" class="title">{{ payment?.amount | currencyFormat:currency?.format:currency?.symbol }}</div>
		<div *ngIf="payment?.description" class="other-details text-truncate">{{ payment?.description }}</div>
	</div>

	<div class="status-details">
		<div class="date-status">
			<ng-container *ngIf="isPastDue">{{ daysPastDue | i18nPlural: {'=1': '1 Day', 'other': '# Days'} }} Past Due</ng-container>
			{{ '' // Should not be UTC date because timezone is already stripped from the date }}
			<ng-container *ngIf="!isPastDue">{{ leftDateLabel }}&nbsp;<ng-container>{{ dateToShow | date:dateFormat }}</ng-container></ng-container>
		</div>

		<!-- Show status if payment is not submitted or viewed status -->
		<div *ngIf="payment?.status !== TransactionStatusEnum.Viewed && payment?.status !== TransactionStatusEnum.Submitted; else showMakePaymentTmpl" class="entity-status" [attr.title]="statusPaidOrPending( payment ) ? getPaidVia( payment ) : ''">
			<button class="status-button">
				<span class="dot medium color-{{ payment?.status | pipeFunction : utils.getTransactionDotColor }}"></span>
				&nbsp;&nbsp;{{ payment?.status | pipeFunction : utils.getTransactionAdjustedStatus }}<span *ngIf="statusPaidOrPending"><span class="status-button-bullet">&#9679;</span>{{ getPaidVia( payment ) }}</span>
			</button>
		</div>

		<!-- Show Make Payment Button if payment is Submitted or Viewed -->
		<ng-template #showMakePaymentTmpl>
			<div class="entity-status">
				<a [href]="payment?.paymentUrl" target="_blank" class="_button _green _rounded _fixed-medium f13 fw-bold _no-side-padding">
					<ng-container *ngIf="isPaymentProcessingEnabled">Make a Payment</ng-container>
					<ng-container *ngIf="!isPaymentProcessingEnabled">View Payment Request</ng-container>
				</a>
			</div>
		</ng-template>

	</div>
</div>
