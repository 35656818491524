import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { format } from 'date-fns';

// Services
import { BaseApiService } from '@app/core/services/baseapi.service';
import { ProposalModelService } from '@app/core/static-services/static.service';
import { CommonService } from '@app/core/services/common.service';

// Utility
import { Utilities } from '@app/shared/utilities';

// Models
import {
	IProposalDetailModel,
	IProposalAreaItemModel,
	IProposalAreaOptionFinancialsModel,
	IProposalFinancialsModel,
	IProposalAcceptedResponse,
	IProposalRecurringServicesModel,
	IProposalChangeOrderGroupModel,
} from '@app/core/models/proposal.models';
import { ErrorModel } from '@app/core/models/error.model';


@Injectable({
	providedIn: 'root',
})
export class ProposalService extends BaseApiService {

	// API URLS
	readonly API_URLS = {
		proposalViewer   : `${this.baseApiUrl}/proposals/{ProposalId}/viewer`,
		downloadViewerPdf: `${this.baseApiUrl}/pdf`,

		acceptProposal   : `${this.baseApiUrl}/proposals/{ProposalId}/viewer/accept`,
		clientResponse   : `${this.baseApiUrl}/proposals/{ProposalId}/viewer/clientresponse`,
		acceptedComment  : `${this.baseApiUrl}/proposals/{ProposalId}/viewer/accept/comment`,

		selectOption     : `${this.baseApiUrl}/proposals/viewer/area/{AreaId}/option/{OptionId}/select`,
		unselectOption   : `${this.baseApiUrl}/proposals/viewer/area/{AreaId}/option/unselect`,

		getTotal         : `${this.baseApiUrl}/proposals/{ProposalId}/viewer/total`,

		// Change Orders
		changeOrdersList        : `${this.baseApiUrl}/proposals/{ProposalId}/viewer/changeorders`,
		getChangeOrderHighlights: `${this.baseApiUrl}/proposals/{ProposalId}/viewer/changes`,
	};


	constructor(
		private _injector    : Injector,
		private commonService: CommonService,
		public utils         : Utilities,
	) {
		super( _injector );
	}


	// Get Proposal Detail
	getDetail( proposalId: number, token: string, clientRef?: string, preview?: string ): Observable<IProposalDetailModel> {
		const dataParams = [];
		const pv         = preview != null ? preview : false;

		dataParams['proposalId'] = proposalId;
		dataParams['token']      = token;
		dataParams['preview']    = pv;

		if ( clientRef ) { dataParams['clientRef'] = clientRef; }

		const requestUrl = this.getProposalUrl( `${this.API_URLS.proposalViewer}`, proposalId );

		return this.httpGet( `${requestUrl}?${ this.generateQueryString( dataParams ) }` )
		.pipe(map(( res: Response ) => {
			return ProposalModelService.getProposalDetailModel( res );
		}));
	}


	// Get Proposal Totals Section
	getProposalTotals( proposalId: number, activeOptionIds: number[] ):
		Observable<{
			financials              : IProposalFinancialsModel,
			affectedItems           : IProposalAreaItemModel[],
			affectedOptionFinancials: { [key: number]: IProposalAreaOptionFinancialsModel },
			recurringServices       : IProposalRecurringServicesModel,
		}> {

		const requestUrl = this.replaceUrlProperty( `${this.API_URLS.getTotal}`, '{ProposalId}', proposalId );


		const dataParams = [];
		dataParams['activeOptionIds'] = activeOptionIds;

		return this.httpGet(`${requestUrl}?${ this.generateQueryString( dataParams ) }`)
		.pipe(map( res => {
			if ( res ) {
				// Affected Area Items because of dynamic pricing changes that happens when area options are switched
				const affectedItemsList: IProposalAreaItemModel[] = ProposalModelService.getAreaItems( res?.['areaItems'] );

				// Affected Option Financials because of dynamic pricing changes that happens when area options are switched
				const affectedOptionFinancials = ProposalModelService.getAffectedOptionFinancials( res?.['areaOptions'] );

				return {
					financials              : ProposalModelService.getProposalFinancialsModel( res?.['financialSummary'], res?.['recurringServices'] ),
					affectedItems           : affectedItemsList,
					affectedOptionFinancials: affectedOptionFinancials,
					recurringServices       : ProposalModelService.getProposalRecurringServicesModel( res?.['recurringServices'] ),
				};
			}

			throw new ErrorModel(null, 'Unexpected Response');
		}));
	}


	// Download pdf file - If file already exists API endpoint sends it otherwise download link is sent via pusher events
	downloadViewerPdf( proposalId: number, token: string, changeOrderIds: number[] ) {

		const dataToSend = {
			entityId               : proposalId,
			fileType               : 'PdfClient',
			secureToken            : token,
			// viewingOptionIds    : viewingOptionsIds || undefined,
			highlightChangeOrderIds: changeOrderIds?.length ? changeOrderIds : undefined,
		};

		return this.httpPost(`${this.API_URLS.downloadViewerPdf}`, dataToSend )
		.pipe(map( res => {
			// Unique process Id sent back from server which we can use to isolate the browser tab that requested the download
			const processId = res?.['processId'];
			const filePath  = res?.['filePath'];

			if ( processId ) {
				// Store process Id in session storage so download is only triggered in this browser tab
				this.commonService.storeDownloadProcessId( processId );
			}

			if ( filePath ) {
				// File already exists - Download file
				this.commonService.downloadFile( filePath );
			}

			// Return if the file exists or not
			return !!filePath;
		}));
	}


	clientResponse( proposalId: number, comment: string, status: string, token: string, clientEmail: string ): Observable<any> {
		const dataToSend = {
			proposalId	: proposalId,
			comment   	: comment,
			status    	: status,
			token     	: token,
			clientEmail	: clientEmail,
		};

		const requestUrl = this.getProposalUrl( `${this.API_URLS.clientResponse}`, proposalId );

		return this.httpPost( requestUrl, dataToSend ).pipe(map( res => res ));
	}


	acceptProposal( proposalId: number, clientEmail: string, signData: any, token: string, clientRef: string ): Observable<IProposalAcceptedResponse> {
		const dataToSend = {
			proposalId : proposalId,
			sign       : signData.sign,
			clientEmail: clientEmail,
			clientName : signData.clientName,
			date       : ( signData.date ? format( signData.date, 'yyyy-MM-dd' ) : null ), 	// Make API compatible date format
			token      : token,
			clientRef  : clientRef,
		};

		const requestUrl = this.getProposalUrl( `${this.API_URLS.acceptProposal}`, proposalId );

		return this.httpPost( requestUrl, dataToSend )
		.pipe(map( res => {
			if ( res ) {

				return {
					payment : ProposalModelService.getProposalPaymentModel( res?.['payment'] ),
					dealerId: res?.['dealerId'],
				};
			}

			throw new ErrorModel(null, 'Unexpected Response');

		}));
	}


	// To send optional comment after proposal is accepted
	acceptedComment( proposalId: number, comment: string, token: string ): Observable<any> {
		const requestUrl = this.replaceUrlProperty( `${this.API_URLS.acceptedComment}`, '{ProposalId}', proposalId );

		const dataToSend = {
			comments  : comment,
			token     : token,
		};
		return this.httpPost( requestUrl, dataToSend ).pipe(map( res => res ));
	}


	selectOption( areaId: number, areaOptionId: number, token: string ): Observable<any> {
		const getUrlWithAreaId = this.replaceUrlProperty( `${this.API_URLS.selectOption}`, '{AreaId}', areaId );
		const requestUrl       = this.replaceUrlProperty( getUrlWithAreaId, '{OptionId}', areaOptionId );

		return this.httpPost( requestUrl, { token } ).pipe(map( res => res ));
	}


	unselectOption( areaId: number, token: string ): Observable<any> {
		const requestUrl = this.replaceUrlProperty( `${this.API_URLS.unselectOption}`, '{AreaId}', areaId );

		return this.httpPost( requestUrl, { token } ).pipe(map( res => res ));
	}


	getChangeOrders( proposalId: number, token: string, clientRef: string, preview: string ): Observable<IProposalChangeOrderGroupModel> {
		const requestUrl = this.replaceUrlProperty( `${this.API_URLS.changeOrdersList}`, '{ProposalId}', proposalId );
		const pv         = preview != null ? preview : false;

		const dataParams = [];

		dataParams['token']     = token;
		dataParams['preview']   = pv;
		dataParams['clientRef'] = clientRef;

		return this.httpGet( `${requestUrl}?${ this.generateQueryString( dataParams ) }` ).pipe( map( res => {
			return ProposalModelService.getProposalChangeOrderGroupModel( res );
		}));
	}


	getChangeOrderHighlights( proposalId: number, token: string, clientRef: string, preview: string ): Observable<IProposalDetailModel> {
		const requestUrl = this.replaceUrlProperty( `${this.API_URLS.getChangeOrderHighlights}`, '{ProposalId}', proposalId );
		const pv         = preview != null ? preview : false;

		const dataParams = [];

		dataParams['token']     = token;
		dataParams['preview']   = pv;
		dataParams['clientRef'] = clientRef;

		return this.httpGet( `${requestUrl}?${ this.generateQueryString( dataParams ) }` )
		.pipe(map(( res: Response ) => {
			return ProposalModelService.getProposalDetailModel( res );
		}));
	}


	getProposalUrl( url: string, proposalId: number ): string {
		return this.replaceUrlProperty( url, '{ProposalId}', proposalId );
	}

}
