import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnChanges, inject, SimpleChanges } from '@angular/core';

// Models
import { ICurrencyModel } from '@app/core/models/common.models';
import { IProposalClientSettingsModel, IProposalFinancialsModel, IProposalRecurringServiceItemModel } from '@app/core/models/proposal.models';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Services
import { ChangeOrderService } from '@app/proposals/services/change-order.service';

// Enums
import { DiscountTypeEnum, ProposalTaxApplyToEnum } from '@app/core/enums';


@Component({
	selector       : 'app-financial-summary',
	templateUrl    : './financial-summary.component.html',
	styleUrls      : ['./financial-summary.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialSummaryComponent implements OnInit, OnChanges {
	public readonly changeOrderService = inject( ChangeOrderService );
	private readonly cd                = inject( ChangeDetectorRef );
	public readonly utils              = inject( Utilities );

	@Input() loader            : boolean                      = false;
	@Input() clientSettings    : IProposalClientSettingsModel = null;
	@Input() financials        : IProposalFinancialsModel     = null;
	@Input() currency          : ICurrencyModel               = null;
	@Input() viewSimpleProposal: boolean                      = false;
	@Input() isHighlightsShown : boolean                      = false;

	readonly DiscountTypeEnum = DiscountTypeEnum;

	private changeOrderFinancials: IProposalFinancialsModel;

	private grandTotal           : number;
	private partsTotal           : number;
	private laborTotal           : number;
	// private partsTaxPercent   : number;
	// private laborTaxPercent   : number;
	// partsTotalTax             : number;
	// laborTotalTax             : number;
	private partsWithoutDiscount : number;
	private partsDiscount        : number;
	private taxAmount            : number;
	private recurringServiceTotal: number;
	recurringServiceItems        : IProposalRecurringServiceItemModel[];
	private totalMsrpDiscount    : number;


	ngOnInit(): void {
		this.changeOrderService.changeTrigger$.subscribe( () => {
			this.updateFinancials();

			this.markForChange();
		});
	}


	ngOnChanges( changes: SimpleChanges ): void {
		this.updateFinancials();
	}


	private updateFinancials(): void {

		const financials = this.financials;

		this.grandTotal            = financials?.grandTotal;
		this.partsTotal            = financials?.partsTotal;
		this.laborTotal            = financials?.laborTotal;
		this.partsWithoutDiscount  = financials?.partsValue;
		this.partsDiscount         = financials?.discountAmount;
		this.taxAmount             = financials?.taxDetails?.taxAmount;
		// this.partsTotalTax         = financials?.taxDetails?.partsTotalTax;
		// this.laborTotalTax         = financials?.taxDetails?.laborTotalTax;
		this.recurringServiceTotal = financials?.recurringServices?.total;
		this.recurringServiceItems = financials?.recurringServices?.items;
		this.totalMsrpDiscount     = financials?.totalMsrpDiscount;

		this.changeOrderFinancials = this.changeOrderService.getCheckedChangeOrderFinancials();
	}


	get calculatedGrandTotal(): number {
		return this.grandTotal + this.changeOrderFinancials?.grandTotal;
	}


	get calculatedPartsTotal(): number {
		return this.partsTotal + this.changeOrderFinancials?.partsTotal;
	}


	get calculatedLaborTotal(): number {
		return this.laborTotal + this.changeOrderFinancials?.laborTotal;
	}


	get calculatedPartsWithoutDiscount(): number {
		return this.partsWithoutDiscount + this.changeOrderFinancials?.partsValue;
	}


	get calculatedPartsDiscount(): number {
		return this.partsDiscount + this.changeOrderFinancials?.discountAmount;
	}


	get calculatedTaxAmount(): number {
		return this.taxAmount + this.changeOrderFinancials?.taxDetails.taxAmount;
	}


	get calculatedTotalMsrpDiscount(): number {
		return this.totalMsrpDiscount + this.changeOrderFinancials?.totalMsrpDiscount;
	}


	get calculatedRecurringServiceTotal(): number {
		return this.recurringServiceTotal + this.changeOrderFinancials?.recurringServices?.total;
	}


	get curFormat(): string {
		return this.currency?.format;
	}


	get curSymbol(): string {
		return this?.currency?.symbol;
	}


	get showPartTax(): boolean {
		return this.financials?.taxDetails?.taxInformation?.applyTo === ProposalTaxApplyToEnum.Both || this.financials?.taxDetails?.taxInformation?.applyTo === ProposalTaxApplyToEnum.Part;
	}


	get showLaborTax(): boolean {
		return this.financials?.taxDetails?.taxInformation?.applyTo === ProposalTaxApplyToEnum.Both || this.financials?.taxDetails?.taxInformation?.applyTo === ProposalTaxApplyToEnum.Labor;
	}


	// Mark for change detection
	private markForChange(): void {
		this.cd.markForCheck();
	}

}
