
@if ( item ) {
	<div class="table-item-detail {{ isOriginalItem && 'change-order-original-line-item original-item change-order-' + changeOrderNumber + '-background-color' }} {{ ( isOriginalItem || isChangeOrderItem ) && 'change-order-number-' + changeOrderNumber }}" [class.recurring]="recurringService" [class.change-order-item]="isChangeOrderItem">

		<ng-container *ngTemplateOutlet="imageTmpl" />

		@switch ( type ) {

			@case ( AreaItemTypeEnum.Part ) {
				<div class="g-detail">
					@if ( isPlaceholder ) {
						<p class="placeholder-description" *ngIf="item?.description">{{ item?.description }}</p>
					} @else {
						<span class="brandname">{{ part?.brandName }}</span>&nbsp;<span *ngIf="!clientSettings?.hideModelNumbers" class="model">{{ part?.model }}</span>
						<p class="description" *ngIf="item?.description">{{ item?.description }}</p>
					}

					<ng-container *ngTemplateOutlet="noteTmpl" />

					<ul class="tags">
						<ng-container *ngTemplateOutlet="taxExemptRecurringTmpl" />
					</ul>
				</div>

				<ng-container *ngTemplateOutlet="priceQtyTmpl; context: { price: item?.sellPrice, quantity: item?.quantity, hide: clientSettings?.hideItemPrices }" />
				<ng-container *ngTemplateOutlet="totalTmpl; context: { item: item, hide: clientSettings?.hideItemPrices }" />
			}

			@case ( AreaItemTypeEnum.Labor ) {
				<div class="g-detail">
					@if ( isPlaceholder ) {
						<p class="placeholder-description" *ngIf="item?.description">{{ item?.description }}</p>
					} @else {
						<span class="labor-title text-break" *ngIf="labor?.name">{{ labor?.name }}</span>
						<p class="description" *ngIf="item?.description">{{ item?.description }}</p>
					}

					<ng-container *ngTemplateOutlet="noteTmpl" />

					<ul class="tags">
						<ng-container *ngTemplateOutlet="taxExemptRecurringTmpl" />
					</ul>
				</div>

				<ng-container *ngTemplateOutlet="priceQtyTmpl; context: { price: item?.sellPrice, quantity: item?.quantity, hide: clientSettings?.hideLaborRateQtyTotal || clientSettings.hideLaborRateQty, qtyHide: clientSettings?.hideLaborRateQtyTotal || clientSettings.hideLaborRateQty }" />
				<ng-container *ngTemplateOutlet="totalTmpl; context: { item: item, hide: clientSettings?.hideLaborRateQtyTotal }" />
			}

			@case ( AreaItemTypeEnum.Custom ) {
				<div class="g-detail">
					<span class="brandname" *ngIf="customItem?.brand">{{ customItem?.brand }}</span>
					<span class="model" *ngIf="!clientSettings?.hideModelNumbers && customItem?.model">{{ customItem?.model }}</span>
					<p class="description" *ngIf="item?.description">{{ item?.description }}</p>

					<ng-container *ngTemplateOutlet="noteTmpl" />

					<ul class="tags">
						<ng-container *ngTemplateOutlet="taxExemptRecurringTmpl" />
					</ul>
				</div>

				<ng-container *ngTemplateOutlet="priceQtyTmpl; context: { price: item?.sellPrice, quantity: item?.quantity, hide: clientSettings?.hideItemPrices }" />
				<ng-container *ngTemplateOutlet="totalTmpl; context: { item: item, hide: clientSettings?.hideItemPrices }" />
			}
		}

	</div>
}


<ng-template #imageTmpl>
	<div class="g-image" *ngIf="!clientSettings?.hideImages">
		<div class="product-img-wrapper">
			<div class="img-fluid img-radius product-img"
				width                     = "42"
				height                    = "42"
				[customClBackgroundImage] = "[ CloudinaryPathEnum.Parts + '/' + item?.imageAssetId + '.png', type === AreaItemTypeEnum.Labor ? CloudinaryPathEnum.LaborPlaceholder : CloudinaryPathEnum.Placeholder ] | pipeFunction: getProductThumbnailImageUrl"
			></div>
		</div>
	</div>
</ng-template>

<ng-template #priceQtyTmpl let-price="price" let-quantity="quantity" let-hide="hide" let-qtyHide="qtyHide">
	<div class="g-sell">
		@if ( !hide && !item?.isCombinePrice ) {
			<span class="item-sell-price">{{ price | currencyFormat:currency?.format:currency?.symbol }}</span>
			<span *ngIf="msrp && msrpDiscount > 0 && !clientSettings?.hideMsrpDiscounts" class="reg-price">reg {{ msrp | currencyFormat:currency?.format:currency?.symbol }}</span>
		} @else {
			<span class="item-sell-price">&nbsp;</span>
		}
	</div>
	<div *ngIf="!qtyHide" class="g-qty">
		<span *ngIf="!isChangeOrderItem && !isChangeOrder" class="item-qty">x{{ quantity }}</span>
		<span *ngIf="isChangeOrderItem || isChangeOrder" class="item-qty">{{ quantity > 0 ? '+' + quantity : quantity }}</span>
	</div>
</ng-template>

<ng-template #totalTmpl let-hide="hide" let-item="item">
	<div class="g-total d-none d-md-block">
		@if ( !recurringService && !hide && !isCombinedParent ) {
			<span class="item-total">{{ item?.totalPrice | currencyFormat:currency?.format:currency?.symbol }}</span>
			<span *ngIf="!nested && item?.isCombinePrice" class="combine-label">Combined Price</span>
			<span *ngIf="msrpDiscount > 0 && !clientSettings?.hideMsrpDiscounts" class="item-discount">{{ msrpDiscount | currencyFormat:currency?.format:currency?.symbol }} Discount</span>
		}
	</div>
</ng-template>

<ng-template #taxExemptRecurringTmpl>
	<li *ngIf="item?.isTaxExempt"><span class="label-pill _smaller">Tax Exempt</span></li>
	<li *ngIf="recurringService"><span class="label-pill _smaller text-nowrap">Recurring Service</span></li>
</ng-template>

<ng-template #noteTmpl>
	<p *ngIf="item?.note" class="fst-italic wrap-note f11 contains-newlines">{{ item?.note }}</p>
</ng-template>
