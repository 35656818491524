<div class="entity-tile change-order-number-{{ changeOrder?.number }}">
	<div class="status-line"></div>
	<div class="client-details">
		<span class="label-pill text-uppercase fw-semibold f10 change-order-pill">Change Order {{ changeOrder?.number }}</span>
		<div *ngIf="changeOrder?.number" class="title">
			<span>{{ changeOrder?.name }}</span>
			@if ( utils.isValueDefined( changeOrder?.amount ) ) {
				<span class="title-bullet">&#9679;</span>
				<span>{{ changeOrder?.amount | currencyFormat:currency?.format:currency?.symbol }}</span>
			}
		</div>
	</div>

	<div class="status-details">
		<div class="entity-status">
			@if ( status === ProposalStatusEnum?.Submitted || status === ProposalStatusEnum?.Viewed || status === ProposalStatusEnum?.ChangesRequired || status === ProposalStatusEnum?.Delayed ) {
				<a href="{{ changeOrder?.previewUrl }}" target="_blank" class="_button _charcoal _rounded _fixed-large f13 fw-bold _with-chevron">
					Review &amp; Accept&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-chevron-right f12 chevron-icon"></i>
				</a>
			}

			@if ( status === ProposalStatusEnum?.Accepted ) {
				<a href="{{ changeOrder?.previewUrl }}" target="_blank" class="_button _new-lighter-grey _rounded _fixed-large f13 fw-bold _with-chevron">
					View Change Order&nbsp;&nbsp;&nbsp;<i class="fa-solid fa-chevron-right f12 chevron-icon"></i>
				</a>

				<div class="date-stamp">Accepted {{ acceptedDate | date:'MMM dd, yyyy':'UTC' }}</div>
			}
		</div>

	</div>
</div>
