"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cloneMap = void 0;
var deepCopy_1 = require("./deepCopy");
/**
 * Creates a clone of `map`.
 *
 * @private
 * @param {Date} typedDate The map to clone.
 * @returns {Object} Returns the cloned map.
 */
function cloneMap(targetMap) {
  var map = new Map();
  targetMap.forEach(function (value, key) {
    return map.set((0, deepCopy_1.deepCopy)(key), (0, deepCopy_1.deepCopy)(value));
  });
  return map;
}
exports.cloneMap = cloneMap;