import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';

// Services
import { ProposalService } from '@app/core/services/proposal.service';
import { CommonService } from '@app/core/services/common.service';

// Directives
import { ModalDirective } from 'ngx-bootstrap/modal';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Models
import { ErrorModel } from '@app/core/models/error.model';
import { IProposalPaymentModel } from '@app/core/models/proposal.models';
import { ICurrencyModel } from '@app/core/models/common.models';

// Enums
import { PaymentTypeEnum } from '@app/core/enums';


@Component({
	selector   : 'app-after-accept-proposal-modal',
	templateUrl: './modal-after-accept-proposal.component.html',
	styleUrls  : ['./modal-after-accept-proposal.component.scss'],
})
export class AfterAcceptProposalModalComponent implements OnInit, OnDestroy {
	@ViewChild('modal', { static: true }) modal: ModalDirective;

	@Input() proposalId                : number;
	@Input() isPaymentProcessingEnabled: boolean;
	@Input() token                     : string;
	@Input() salesPerson               : string;
	@Input() payment                   : IProposalPaymentModel;
	@Input() currency                  : ICurrencyModel;

	@Output() eventCloseModal        = new EventEmitter<void>();
	@Output() eventModalRef          = new EventEmitter<ModalDirective>();
	@Output() eventMakePaymentButton = new EventEmitter<void>();
	@Output() eventReloadParent      = new EventEmitter<void>();

	readonly alertContainer = 'afterAcceptProposalModal';

	comment : string  = '';
	loader  : boolean = false;
	addNote : boolean = false;
	payLater: boolean = false;

	private subs: Subscription = new Subscription();


	constructor(
		private proposalService: ProposalService,
		private commonService  : CommonService,
		public utils           : Utilities,
	) { }


	ngOnInit() {

		// Emit a modal ref if parent component wants to use its properties
		this.eventModalRef.emit( this.modal );

		// Have to clear previous error as their states are stored globally
		this.utils.clearPreviousErrors( this.alertContainer );

		// Show modal once it has stablized little
		setTimeout( () => this.modal.show(), 10 );
	}


	ngOnDestroy() {
		if ( this.subs ) {
			this.subs.unsubscribe();
		}
	}


	public hideModal(): void {
		this.eventCloseModal.emit();
		this.modal.hide();
	}


	public hideLoading(): void {
		this.loader = false;
	}


	public showLoading(): void {
		this.loader = true;
	}


	showNoteField(): void {
		this.addNote = true;
	}


	showPayLater(): void {
		this.payLater = true;
	}


	makePaymentButtonClicked(): void {
		this.eventMakePaymentButton.emit();
	}


	get paymentOptionsText(): string {
		if ( this.payment ) {
			const isCc 	= this.payment.paymentTypes.indexOf( PaymentTypeEnum.Cc ) > -1 ? true : false;
			const isAch = this.payment.paymentTypes.indexOf( PaymentTypeEnum.Ach ) > -1 ? true : false;

			if ( isCc && isAch ) {
				return 'We can accept a digital check (ACH) or credit card now on our secure payment portal.';
			} else if ( isCc ) {
				return 'We can accept a credit card now on our secure payment portal.';
			} else if ( isAch ) {
				return 'We can accept a digital check (ACH) now on our secure payment portal.';
			}
		}

		return '';
	}


	submitComment(): void {
		this.utils.clearPreviousErrors( this.alertContainer );
		this.showLoading();

		this.subs.add(
			this.proposalService.acceptedComment( this.proposalId, this.comment, this.token ).subscribe(
				( res ) => {
					this.hideLoading();

					this.hideModal();

					this.eventReloadParent.emit();
				},
				( error: ErrorModel ) => {
					this.utils.handleError( this.commonService.getErrorText( error ), { containerName: this.alertContainer } );

					this.hideLoading();
				}
			)
		);
	}

}
