import { Pipe, PipeTransform } from '@angular/core';

/*
	Purpose: To use a component function as a pipe instead of creating different custom pipes for each template function
	Example 1:
		FROM: <span>{{ getDotColor( status ) }}</span>

		TO: <span>{{ status | pipeFunction:getDotColor }}

		OR: <span>{{ status | pipeFunction:getDotColor:this }}		// To bind component `this` as context

		OR: <span>{{ [ 12345678, 60, 50 ] | pipeFunction:getImageUrl }}		// To pass multiple arguments - but method should support it

		getImageUrl( [ assetId, width, height ]: [ string, number, number ] ): string {
			return ImageUtils.getCloudinaryLegacyUrl( assetId, [
				{ width, height, gravity: 'center', crop: 'limit' },
				{ default_image: CloudinaryPathEnum.Placeholder },
			]);
		}
*/
// Copied from: https://github.com/ArtemLanovyy/ngx-pipe-function
@Pipe({
	name: 'pipeFunction',
	standalone: true,
})
export class PipeFunction implements PipeTransform {

	public transform<T>( value: any, handler: (value: any) => T, context?: any ): T {
		if ( context ) {
			return handler.call( context, value );
		}

		return handler( value );
	}

}
