import { Component, OnInit, OnChanges, OnDestroy, AfterViewInit, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { SignaturePad } from '@viablelogic/ngx-signature-pad';
import { format, isValid, parse } from 'date-fns';

// Services
import { ProposalService } from '@app/core/services/proposal.service';
import { CommonService } from '@app/core/services/common.service';

// Directives
import { ModalDirective } from 'ngx-bootstrap/modal';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Models
import { ErrorModel } from '@app/core/models/error.model';
import { IProposalAcceptedResponse } from '@app/core/models/proposal.models';

// Masks
import { MonthDayYearMask } from '@app/shared/masks';


@Component({
	selector       : 'app-accept-proposal-modal',
	templateUrl    : './modal-accept-proposal.component.html',
	styleUrls      : ['./modal-accept-proposal.component.scss'],
	// changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AcceptProposalModalComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('modal', { static: true }) modal: ModalDirective;
	@ViewChild('signaturePad') signaturePad: SignaturePad;

	@Input() proposalId : number = null;
	@Input() token      : string = null;
	@Input() clientEmail: string = null;
	@Input() clientRef  : string = null;

	@Output() eventCloseModal: EventEmitter<void>                      = new EventEmitter();
	@Output() eventModalRef  : EventEmitter<ModalDirective>            = new EventEmitter();
	@Output() eventAccepted  : EventEmitter<IProposalAcceptedResponse> = new EventEmitter();

	readonly alertContainer = 'acceptProposalModal';

	loader: boolean = false;

	readonly dateMask = MonthDayYearMask;

	dateField: string;

	readonly signData : {
		clientName: string;
		sign      : string;
		date      : Date;
	} = {
			clientName: '',
			sign      : null,
			date      : null,
		};

	readonly signaturePadOptions = { // passed through to szimek/signature_pad constructor
		minWidth    : 0.5,			// Line size
		canvasWidth : 400,
		canvasHeight: 160,
	};

	private readonly subs: Subscription = new Subscription();


	constructor(
		private proposalService: ProposalService,
		private commonService  : CommonService,
		public utils           : Utilities,
	) { }


	ngOnInit() {
		// Emit a modal ref if parent component wants to use its properties
		this.eventModalRef.emit( this.modal );

		// Have to clear previous error as their states are stored globally
		this.utils.clearPreviousErrors( this.alertContainer );

		// Show modal once it has stabilized little
		setTimeout( () => this.modal.show(), 10 );

		const currentDate = format( new Date(), 'MM/dd/yyyy' );
		this.dateField = '' + currentDate;	// Convert to string for mask
	}


	ngAfterViewInit() {
		// this.signaturePad is now available
		this.signaturePad.set('minWidth', 0.5); // set szimek/signature_pad options at runtime
		this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
	}


	private showError( error: string ) {
		this.utils.handleError( error, { containerName: this.alertContainer } );
	}


	ngOnDestroy() {
		if ( this.subs ) {
			this.subs.unsubscribe();
		}
	}


	drawComplete(): void {
		// will be notified of szimek/signature_pad's onEnd event
		// console.log(this.signaturePad.toDataURL());
	}


	drawStart(): void {

	}


	clearSign(): void {
		this.signaturePad.clear();
	}


	isFormValid(): boolean {
		return this.signData.clientName && this.dateField && !this.signaturePad.isEmpty();
	}


	public hideModal(): void {
		this.eventCloseModal.emit();
		this.modal.hide();
	}


	public hideLoading(): void {
		this.loader = false;
	}


	public showLoading(): void {
		this.loader = true;
	}


	submitAccept() {
		this.utils.clearPreviousErrors( this.alertContainer );

		if ( !this.signData.clientName ) {
			this.showError( 'Your Full Name is required' );
			return;
		}

		if ( !this.dateField ) {
			this.showError( 'Date is required' );
			return;
		}

		if ( this.signaturePad.isEmpty() ) {
			this.showError( 'Your signature is required' );
			return;
		}

		this.signData.sign = this.signaturePad.toDataURL();
		this.signData.date = parse( this.dateField, 'MMddyyyy', new Date() );	// Convert to proper date from custom format

		if ( !isValid( this.signData.date ) ) {
			this.showError( 'Date is not correct' );
			return;
		}

		this.showLoading();

		this.subs.add(
			this.proposalService.acceptProposal( this.proposalId, this.clientEmail, this.signData, this.token, this.clientRef ).subscribe({
				next: ( response: IProposalAcceptedResponse ) => {
					this.hideLoading();

					this.hideModal();

					this.eventAccepted.emit( response );
				},
				error: ( error: ErrorModel ) => {
					this.utils.handleError( this.commonService.getErrorText( error ), { containerName: this.alertContainer } );

					this.hideLoading();
				},
			})
		);
	}

}
