import { Injectable } from '@angular/core';

import { AlertModel } from '@app/core/models/alert.model';


@Injectable({
	providedIn: 'root',
})
export class AlertService {

	/*
		Map and Set are ES6 entities
		- 'Map' holds string based containers
		- Each container will have a 'Set' of AlertModels
		Similar to (but using modern syntax):
		{
			'container1': [],
			'container2': [],
		}
	*/
	private alerts: Map<string, Set<AlertModel>> = new Map();


	getAlerts( container = 'global' ): Set<AlertModel> {
		return this.getAlertsContainer( container );
	}


	// Add an alert to container
	addAlert( alert: AlertModel, container = 'global', repeat = false ): void {
		const alertsContainer: Set<AlertModel> = this.getAlertsContainer( container );

		if ( !repeat ) {
			let foundAlready = false;
			alertsContainer.forEach( (_alert: AlertModel) => {
				if ( _alert.type === alert.type && _alert.message === alert.message ) {
					foundAlready = true;
				}
			});
			// Alert already found - return back
			if ( foundAlready ) {
				return;
			}
		}

		// Push in alert array specific to container
		alertsContainer.add( alert );
	}


	// Close open alert
	closeAlert( alert: AlertModel, container = 'global' ): void {
		const alertsContainer: Set<AlertModel> = this.getAlertsContainer( container );

		if ( alertsContainer.has( alert ) ) {
			alertsContainer.delete( alert );  // Remove alert from Set
		}
	}


	// Close all open alerts within same container
	closeAllContainerAlerts( container = 'global', evenNonDismissible: boolean = false ): void {
		const alertsContainer: Set<AlertModel> = this.getAlertsContainer( container );

		alertsContainer.forEach( (alert: AlertModel) => {
			if ( !evenNonDismissible ) {
				// Dont remove dismissible = false alerts
				if ( alert && alert.dismissible === false ) {
					return;
				}
			}
			if ( alertsContainer.has( alert ) ) {
				alertsContainer.delete( alert );  // Remove alert from Set
			}
		});
	}


	// Close all open alerts
	closeAllAlerts( evenNonDismissible: boolean = true ): void {
		if ( evenNonDismissible ) {
			this.alerts.clear();
		} else {
			// Grab all containers
			this.alerts.forEach( (containerAlerts: Set<AlertModel>) => {
				// Grab all alerts within container
				containerAlerts.forEach( (alert: AlertModel) => {
					if ( !evenNonDismissible ) {
						// Dont remove dismissible = false alerts
						if ( alert && alert.dismissible === false ) {
							return;
						}
					}
					if ( containerAlerts.has( alert ) ) {
						containerAlerts.delete( alert );  // Remove alert from Set
					}
				});
			});
		}
	}


	// Get alert container array
	private getAlertsContainer( containerName: string ): Set<AlertModel> {
		if ( !this.alerts.has( containerName ) ) {
			this.alerts.set( containerName, new Set<AlertModel>([]) );
		}

		return this.alerts.get( containerName );
	}

}
