<div class="payment-schedule-container f13" *ngIf="isPaymentScheduleShown">
	<div class="payment-schedule-table color-black f13">
		<app-subtle-label class="bigger gray">Payment Schedule</app-subtle-label>
		<app-br class="grid-fixed" />
		<div *ngFor="let item of scheduledPayments; let i = index; trackBy: ( 'id' | trackByProperty )" class="payment-schedule-row">
			<div class="payment-schedule-cell payment-number text-nowrap">
				<div>Payment {{ i + 1 }}</div>
			</div>

			<div class="payment-schedule-cell payment-type">
				<ng-container [ngSwitch]="item?.itemType">
					<ng-container *ngSwitchCase="PaymentScheduleItemTypeEnum.Fixed">
						<span>Fixed Amount <span class="cost-separator">&#9679;</span> {{ getAmountFromItemType( item ) | currency:'USD':'symbol'  }}</span>
					</ng-container>

					<ng-container *ngSwitchCase="PaymentScheduleItemTypeEnum.Percentage">
						<span>{{ item?.percent }}% of {{ getPaymentPurposeValueFromEnum( item?.percentageBasis ) }} <span class="cost-separator">&#9679;</span> {{ getAmountFromItemType( item ) | currency:'USD':'symbol'  }}</span>
					</ng-container>

					<ng-container *ngSwitchCase="PaymentScheduleItemTypeEnum.Remaining">
						<span>Outstanding {{ isProject ? 'Project' : 'Proposal' }} Balance <span class="cost-separator">&#9679;</span> {{ getAmountFromItemType( item ) | currency:'USD':'symbol'  }}</span>
					</ng-container>

				</ng-container>
			</div>

			<div class="payment-schedule-cell milestone">
				<ng-container *ngIf="item?.dueDate; else showMilestone">
					Due {{ item?.dueDate | date:'MMM dd, yyyy':'UTC' }}
				</ng-container>

				<ng-template #showMilestone>
					Due Upon {{ getMilestoneText( item?.milestone, item?.milestoneType ) }}
				</ng-template>
			</div>
		</div>
	</div>
</div>
