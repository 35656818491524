import { Component, ChangeDetectionStrategy, inject, Input } from '@angular/core';

// Utility
import { Utilities } from '@app/shared/utilities';

// Models
import { ICurrencyModel } from '@app/core/models/common.models';
import { IProposalChangeOrderListModel } from '@app/core/models/proposal.models';

// Enums
import { ProposalStatusEnum } from '@app/core/enums';


@Component({
	selector       : 'app-change-order-list-item',
	templateUrl    : './change-order-list-item.component.html',
	styleUrls      : ['./change-order-list-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangeOrderListItemComponent {
	public readonly utils = inject( Utilities );

	@Input({ required: true }) changeOrder: IProposalChangeOrderListModel;
	@Input({ required: true }) currency   : ICurrencyModel;

	readonly ProposalStatusEnum = ProposalStatusEnum;


	get status(): ProposalStatusEnum {
		return this.changeOrder?.status;
	}


	get acceptedDate(): Date {
		return this.changeOrder?.acceptedDate;
	}
}
