import { Injectable } from '@angular/core';
import { CloudinaryPathEnum } from '@app/core/enums';
import { createCloudinaryLegacyURL } from '@cloudinary/url-gen';
import { ImageEffect, stringOrNumber } from '@cloudinary/url-gen/types/types';

// Environment
import { environment } from '@env/environment';


@Injectable({
	providedIn: 'root',
})
export class ImageUtils {


	static getCloudinaryLegacyUrl( id: string, transformation: {[key in string]}[] = [], effect?: string | stringOrNumber[] | ImageEffect ): string {

		if ( !transformation?.find( t => t?.['fetch_format'] ) ) {
			// Append fetch_format to auto if not defined already to allow webp, Ref: https://cloudinary.com/blog/transparent_webp_format_cdn_delivery_based_on_visitors_browsers
			if ( transformation?.length === 0 ) {
				transformation.push({});
			}

			transformation[0]['fetch_format'] = 'auto';
		}

		const generatedUrl = createCloudinaryLegacyURL( id, {
			cloud_name: environment.cloudinaryCloudName,
			secure: true,
			transformation,
			effect,
		});

		if ( generatedUrl?.indexOf( 'e_trim' ) !== -1 ) {
			/* Moves e_trim from one part of the URL to the other
				- Converts:
				/portal-dev/image/upload/c_lpad,dpr_2.0,g_center,h_168,w_210/e_trim:0/v1/SupplyStream/Part/4148293.png
				- To:
				/portal-dev/image/upload/e_trim:0/c_lpad,dpr_2.0,g_center,h_168,w_210/v1/SupplyStream/Part/4148293.png
			*/

			// Match /e_trim/ and /e_trim:0-9/
			const regex = /\/(e_trim[:]*[0-9]*)\//gm;

			// Find the e_trim value - store it in a variable
			const foundTrim = [ generatedUrl.match( regex ) ]?.[0];

			// Replace with just a slash '/' and move it right after the /upload/ string
			return generatedUrl?.replace( regex, '/' ).replace( '/upload/', `/upload/${foundTrim}` );
		}

		return generatedUrl;
	}


	static getCompanyLogoImageUrl( [ assetId ]: [ string ] ): string {
		return ImageUtils.getCloudinaryLegacyUrl( assetId, [
			{ width: 475, height: 150, gravity: 'center', crop: 'limit', default_image: CloudinaryPathEnum.CompanyPlaceholder },
		]);
	}


	static getProductSmallImageUrl( [ assetId, placeholder ]: [ string, string ] ): string {
		return ImageUtils.getProductImageUrl( assetId, 340, 240, placeholder );
	}


	static getProductThumbnailImageUrl( [ assetId, placeholder ]: [ string, string ] ): string {
		return ImageUtils.getProductImageUrl( assetId, 110, 110, placeholder );
	}


	private static getProductImageUrl( assetId: string, width: number, height: number, placeholder: string ): string {
		return ImageUtils.getCloudinaryLegacyUrl( assetId,
			[{ width, height, gravity: 'center', crop: 'limit', background: 'transparent', default_image: placeholder ?? CloudinaryPathEnum.Placeholder }]
		);
	}


	static getCoverLargeImageUrl( [ assetId ]: [ string ] ): string {
		return ImageUtils.getCoverImageUrl( assetId, 1200, 650 );
	}


	static getCoverSmallImageUrl( [ assetId ]: [ string ] ): string {
		return ImageUtils.getCoverImageUrl( assetId, 275, 180 );
	}


	private static getCoverImageUrl( assetId: string, width: number, height: number ): string {
		return ImageUtils.getCloudinaryLegacyUrl( assetId, [
			{ width: width, height: height, gravity: 'north', crop: 'fill', default_image: CloudinaryPathEnum.CompanyPlaceholder },
		]);
	}

}
