import { Injectable } from '@angular/core';
import { deepCopy } from 'deep-copy-ts';


@Injectable({
	providedIn: 'root',
})
export class ClonerService {

	deepClone<T>( value: any ): T {
		return deepCopy<T>( value );
	}

}
