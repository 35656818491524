
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Component
import { ProposalDetailComponent } from '@app/proposals/proposal-detail/proposal-detail.component';

// Models
import { IProposalAreaItemModel, IProposalChangeOrderDataModel, IProposalChangeOrderListModel, IProposalDetailModel, IProposalFinancialsModel } from '@app/core/models/proposal.models';

// Enums
import { ChangeOrderItemActionEnum } from '@app/core/enums';


@Injectable()
export class ChangeOrderService {
	private readonly utils = inject( Utilities );

	public readonly changeTrigger$ = new BehaviorSubject( 0 );

	private _acceptedChangeOrders: IProposalChangeOrderListModel[];
	private _pendingChangeOrders : IProposalChangeOrderListModel[];

	private changeOrderFinancials: IProposalDetailModel['changeOrderFinancials'];

	private parentRef: ProposalDetailComponent;

	private toShowChangeOrders = new Set<number>();


	setComponentRef( ref: ProposalDetailComponent ): void {
		this.parentRef = ref;
	}


	get proposalId(): number {
		return this.parentRef?.proposalId;
	}


	get getParentClass(): ProposalDetailComponent {
		return this.parentRef;
	}


	setChangeOrders( { acceptedChangeOrders, pendingChangeOrders }: { acceptedChangeOrders: IProposalChangeOrderListModel[], pendingChangeOrders: IProposalChangeOrderListModel[] } ): void {
		this._acceptedChangeOrders = acceptedChangeOrders;
		this._pendingChangeOrders  = pendingChangeOrders;
	}


	setChangeOrderFinancials( changeOrderFinancials: IProposalDetailModel['changeOrderFinancials'] ): void {
		this.changeOrderFinancials = changeOrderFinancials;
	}


	get hasChangeOrders(): boolean {
		return !!( this._acceptedChangeOrders?.length || this._pendingChangeOrders?.length );
	}


	get allChangeOrders(): IProposalChangeOrderListModel[] {
		return [ ...this._acceptedChangeOrders, ...this._pendingChangeOrders ];
	}


	get acceptedChangeOrders(): IProposalChangeOrderListModel[] {
		return this._acceptedChangeOrders;
	}


	get pendingChangeOrders(): IProposalChangeOrderListModel[] {
		return this._pendingChangeOrders;
	}


	get isChangeOrder(): boolean {
		return this.parentRef?.isChangeOrder;
	}


	getChangeOrderInfo( changeOrderId: number ): { previewUrl: string, status: string, acceptedDate: Date, number: number } {

		const changeOrder = this._acceptedChangeOrders?.find( co => co?.id === changeOrderId ) || this._pendingChangeOrders?.find( co => co?.id === changeOrderId );

		if ( !changeOrder ) {
			return null;
		}

		return {
			previewUrl   : changeOrder.previewUrl,
			status       : changeOrder.status,
			acceptedDate : changeOrder.acceptedDate,
			number       : changeOrder.number,
		};
	}


	// Get change order data for area option id and change order id
	getChangeOrderData( areaId: number, areaOptionId: number, changeOrderId: number ): IProposalChangeOrderDataModel {

		const area            = this.parentRef?.proposal?.areas?.filter( a => a?.id === areaId )?.[0];
		const option          = area?.options?.filter( o => o?.id === areaOptionId )?.[0];
		const changeOrderData = option?.changeOrderData?.[changeOrderId];

		return changeOrderData;
	}


	// Get change order items for each item (by matching referenceItemId)
	getChangeOrderItems( areaId: number, areaOptionId: number, changeOrderId: number, referenceId: number ): IProposalAreaItemModel[] {

		const changeOrderData  = this.getChangeOrderData( areaId, areaOptionId, changeOrderId );
		const changeOrderItems = changeOrderData?.items?.filter( i => i?.referenceLineItemId === referenceId );

		if ( this.toShowChangeOrders.has( changeOrderId ) ) {
			return changeOrderItems;
		}

		return [];
	}


	showChangeOrderData( changeOrderId: number ): boolean {
		return this.toShowChangeOrders.has( changeOrderId );
	}


	updateToShowChangeOrders( changeOrderIds: number[] ): void {
		this.toShowChangeOrders.clear();

		changeOrderIds?.forEach( id => this.toShowChangeOrders.add( id ) );

		// Update change trigger to trigger change detection
		this.changeTrigger$.next( this.changeTrigger$.getValue() + 1 );
	}


	getCheckedChangeOrderData( areaId: number, areaOptionId: number ): IProposalChangeOrderDataModel[] {
		const checkedChangeOrderIds = Array.from( this.toShowChangeOrders.keys() ).sort();	// Sort to show based on change order ids

		const model: IProposalChangeOrderDataModel[] = [];

		if ( checkedChangeOrderIds?.length ) {
			for ( const changeOrderId of checkedChangeOrderIds ) {
				const data = this.getChangeOrderData( areaId, areaOptionId, changeOrderId );
				if ( data ) {
					model.push( data );
				}
			}
		}

		return model;
	}


	// TODO: NOT IN USE ANYMORE
	getCheckedChangeOrderAddedItems( areaId: number, areaOptionId: number ): IProposalAreaItemModel[] {
		const checkedChangeOrderData = this.getCheckedChangeOrderData( areaId, areaOptionId );

		const addedItems: IProposalAreaItemModel[] = [];

		if ( checkedChangeOrderData?.length ) {
			for ( const changeOrder of checkedChangeOrderData ) {
				const items = changeOrder?.items?.filter( i => i?.changeOrderActionType === ChangeOrderItemActionEnum.Added ) || [];

				addedItems.push( ...items );
			}
		}

		return addedItems;
	}


	getCheckedChangeOrderMiscItems( areaId: number, areaOptionId: number ): { changeOrderId: number, changeOrderNumber: number, items: IProposalAreaItemModel[] }[] {
		const checkedChangeOrderData = this.getCheckedChangeOrderData( areaId, areaOptionId );

		const model: { changeOrderId: number, changeOrderNumber: number, items: IProposalAreaItemModel[] }[] = [];

		if ( checkedChangeOrderData?.length ) {
			for ( const changeOrder of checkedChangeOrderData ) {
				// Grab checked change order misc items which are parent items only
				const items = changeOrder?.items?.filter( i => i?.referenceLineItemId === null && i?.parentId === 0 ) || [];

				model.push( { changeOrderId: changeOrder?.id, changeOrderNumber: changeOrder?.changeOrderNumber, items: items } );
			}
		}

		return model;
	}


	getCheckedChangeOrderAreaTotal( areaId: number, areaOptionId: number ): number {
		return this.getCheckedChangeOrderData( areaId, areaOptionId )?.reduce( ( acc, curr ) => acc + curr?.areaOptionTotal, 0 );
	}


	getCheckedChangeOrderRecurringServicesTotal( areaId: number, areaOptionId: number ): number {
		return this.getCheckedChangeOrderData( areaId, areaOptionId )?.reduce( ( acc, curr ) => acc + curr?.recurringServiceTotal, 0 );
	}


	getCheckedChangeOrderFinancials(): IProposalFinancialsModel {
		const checkedChangeOrderIds = Array.from( this.toShowChangeOrders.keys() );

		const model: IProposalFinancialsModel = {
			grandTotal               : 0,
			partsTotal               : 0,
			laborTotal               : 0,
			partsValue               : 0,
			discountAmount           : 0,
			totalMsrpDiscount        : 0,
			discountPercent          : 0,
			discountType             : null,
			taxDetails               : { taxAmount: 0, partsTotalTax: 0, laborTotalTax: 0, taxInformation: null },
			recurringServices        : { total: 0, items: [] },
			projectTotal             : 0,
			acceptedChangeOrdersTotal: 0,
			projectBalance           : 0,
			paidPaymentRequestTotal  : 0,
		};

		if ( checkedChangeOrderIds?.length ) {
			for ( const changeOrderId of checkedChangeOrderIds ) {
				const financials = this.changeOrderFinancials[ changeOrderId ];

				model.grandTotal               += this.utils.isValueDefined( financials?.grandTotal ) ? financials?.grandTotal : 0;
				model.partsTotal               += this.utils.isValueDefined( financials?.partsTotal ) ? financials?.partsTotal : 0;
				model.laborTotal               += this.utils.isValueDefined( financials?.laborTotal ) ? financials?.laborTotal : 0;
				model.partsValue               += this.utils.isValueDefined( financials?.partsValue ) ? financials?.partsValue : 0;
				model.discountAmount           += this.utils.isValueDefined( financials?.discountAmount ) ? financials?.discountAmount : 0;
				model.totalMsrpDiscount        += this.utils.isValueDefined( financials?.totalMsrpDiscount ) ? financials?.totalMsrpDiscount : 0;
				model.taxDetails.taxAmount     += this.utils.isValueDefined( financials?.taxDetails?.taxAmount ) ? financials?.taxDetails?.taxAmount : 0;
				model.taxDetails.partsTotalTax += this.utils.isValueDefined( financials?.taxDetails?.partsTotalTax ) ? financials?.taxDetails?.partsTotalTax : 0;
				model.taxDetails.laborTotalTax += this.utils.isValueDefined( financials?.taxDetails?.laborTotalTax ) ? financials?.taxDetails?.laborTotalTax : 0;
				model.recurringServices.total  += this.utils.isValueDefined( financials?.recurringServices?.total ) ? financials?.recurringServices?.total : 0;
			}
		}

		return model;
	}
}
