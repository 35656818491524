import Bugsnag from '@bugsnag/js';

// Environment
import { environment } from '@env/environment';

// External Variables
import { externalVariables } from '@env/external-variables';

// configure Bugsnag ASAP, before any other imports
export const bugsnagClient = loadBugsnagClient();

function loadBugsnagClient() {
	if ( environment.name !== 'local' ) {
		return Bugsnag.start({
			apiKey             : 'ae3e7cc626ca4afe92ab6585a8c639c5',
			releaseStage       : environment.name,
			appVersion         : externalVariables.appVersion,		// Jenkins will change to match release version
			autoTrackSessions  : true,								// To enable crash rates between releases to understand release quality
		});
	}

	return null;
}
