import { FormControl, FormArray } from '@angular/forms';
import { Injectable, TemplateRef } from '@angular/core';
import { bugsnagClient } from '@app/core/bugsnag-client';
import { formatNumber } from '@angular/common';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';

// Components
import { SnackbarContainerComponent } from '@app/shared/components/snackbar-container/snackbar-container.component';

// Services
import { AlertService } from '@app/core/services/alert.service';
import { ClonerService } from '@app/core/services/clone.service';

// Models
import { AlertModel } from '@app/core/models/alert.model';
import { IMatSnackBarData, ISnackBarConfig, SnackBarMode } from '@app/core/models/snackbar.model';

// Environment
import { environment } from '@env/environment';

// Enums
import { ChangeOrderItemActionEnum, GatewayTypeEnum, ProposalStatusEnum, TransactionStatusEnum } from '@app/core/enums';


@Injectable({
	providedIn: 'root',
})
export class Utilities {


	// Based on spec from https://docs.google.com/spreadsheets/d/1PnRXHjk47f29svYmR8Kkp5xmlW5v1126Is9FWTiSdi8/edit#gid=0
	static formatCurrency( value: number, format: string = 'LN333,.2F', symbol: string = '$' ): string | number {
		if ( format ) {
			let output: string = '';

			const symbolPosition      = format[0];
			const symbolSpacing       = format[1];
			const groupingOne         = format[2];
			const groupingTwo         = format[3];	// Not implemented as yet
			const groupingThree       = format[4];	// Not implemented as yet
			const groupingSymbol      = format[5]?.replace( 'S', ' ' )?.replace( 'N', '' );
			const decimalSymbol       = format[6]?.replace( 'S', ' ' )?.replace( 'N', '' );
			const numberDecimalPlaces = format[7];
			const decimalForced       = format[8];

			// Fractional part - if Its Forced (F) use `numberDecimalPlaces` otherwise 0
			const minIntegerDigits  = 1;
			const minFractionDigits = decimalForced?.toUpperCase() === 'F' ? +numberDecimalPlaces : 0;
			const maxFractionDigits = +numberDecimalPlaces;

			const numberSection = Utilities.groupNumber( value, +groupingOne, groupingSymbol, decimalSymbol, minIntegerDigits, minFractionDigits, maxFractionDigits );
			const symbolSpace   = symbolSpacing?.toUpperCase() === 'S' ? ' ' : '';

			if ( symbol ) {
				if ( symbolPosition?.toUpperCase() === 'L' ) {
					output += `${ symbol }${ symbolSpace }${ numberSection }`;
				} else {
					// If symbol is on right - there should be a space between number part and symbol
					output += `${ numberSection }${ symbolSpace }${ symbol }`;
				}
			}

			return output;
		}

		return value;
	}


	// Add comma/group symbol - decimal symbol and separate numbers into groups
	static groupNumber( value: number, groupAfter: number = 3, groupSymbol: string = ',', decimalSymbol: string = '.', minIntegerDigits: number = 1, minFractionDigits: number = 0, maxFractionDigits: number = 5 ): string {
		// Use Angular's formatNumber to generate US based standard string to extract decimal digits and rounding
		const strValue = formatNumber( value, 'en-US', `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}` );

		const str = strValue?.split('.');

		// Remove all commas (if any) in non-fractional part
		str[0] = str?.[0]?.replace(/,/g, '');

		// Non-fractional part
		if ( str?.[0]?.length >= ( groupAfter + 1 ) ) {
			if ( groupAfter > 0 ) {
				str[0] = str[0].replace( new RegExp( `\(\\d\)\(\?\=\(\\d\{${groupAfter}\}\)\+\$\)`, 'g' ), `$1${groupSymbol}` );
			}
		}

		// Fractional part
		if ( str?.[1]?.length >= ( groupAfter + 1 ) ) {
			str[1] = str[1].replace( new RegExp( `\(\\d\{${groupAfter}\}\)`, 'g' ), '$1' );
		}

		return str.join( decimalSymbol );
	}


	static getIfNumericValue( value: number | string ): number {
		if ( value === 0 ) {
			return 0;
		}
		if ( !value ) {
			// Handles null, undefined, empty string
			return null;
		}

		return isNaN( +value ) ? null : +value;
	}


	constructor(
		private alertService   : AlertService,
		private clonerService  : ClonerService,
		private snackBarService: MatSnackBar,
	) {	}


	// Convert PX to REM
	_rem( pxValue: string|number ): string {

		const baselineRem = 16 / 1;
		if ( typeof pxValue === 'string' ) {
			pxValue = pxValue.replace('px', '');
		}
		return (pxValue as number / baselineRem) + 'rem';
	}


	// Condition to mark field with error
	fieldErrorCondition( fieldName: FormControl|FormArray, additionalCheck: boolean = false ): boolean {

		// Additional check is true
		if ( additionalCheck ) { return true; }

		if ( fieldName ) {
			return fieldName.invalid && ( fieldName.dirty || fieldName.touched );
		}
		return false;
	}


	// Scroll back to top of given element
	scrollToTop( element, scrollDuration: number ): void {
		if ( !element || !element.scrollTop ) { return; }

		const scrollStep = element.scrollTop / (scrollDuration / 15);
		let scrollInterval = null;

		// Clear interval either way in case something goes wrong
		setTimeout( () => { clearInterval(scrollInterval); }, (scrollDuration * 2) );
		scrollInterval = setInterval( () => {
			if ( element.scrollTop !== 0 ) { element.scrollTop -= scrollStep; } else { clearInterval(scrollInterval); }
		}, 15);
	}


	get latestTimestamp(): number {
		return Math.floor(Date.now() / 1000);
	}


	// Finds position of an HTML object - Ref: https://stackoverflow.com/questions/5007530/how-do-i-scroll-to-an-element-using-javascript
	findPosition( obj ): {left: number, top: number} {
		let curleft = 0;
		let	curtop  = 0;
		do {
			curleft += obj.offsetLeft;
			curtop  += obj.offsetTop;
		// tslint:disable-next-line:no-conditional-assignment
		} while (obj = obj.offsetParent);

		return {
			left: curleft,
			top : curtop,
		};
	}


	// Scroll to element by reference to its container
	scrollToElement( element, container, offSet: number = 0 ) {

		if ( !element || !container ) { return; }

		try {
			let makeRelative = false;
			let direction = 'up';

			// Make container relative (if not already) so that offsetTop of element can be calculated properly
			if ( container.style.position !== 'relative' ) { makeRelative = true; }
			if ( makeRelative ) { container.style.position = 'relative'; }

			const interval = setInterval(() => {
				const yPos  = element.offsetTop - offSet;
				let yScroll = container.scrollTop;

				if ( yPos <= yScroll ) {
					direction = 'up';
					yScroll -= 50;
				} else {
					direction = 'down';
					yScroll += 50;
				}
				container.scrollTop = yScroll;

				if ( (direction === 'up' && yPos >= yScroll) || (direction === 'down' && yPos <= yScroll) ) {
					container.scrollTop = yPos;
					clearInterval(interval);
					// If container was made relative, remove relative property
					if ( makeRelative ) { container.style.position = null; }
				}
			}, 10);

			// Clear interval after few milliseconds just in case the logic fails
			setTimeout( () => {
				clearInterval(interval);
				// If container was made relative, remove relative property
				if ( makeRelative ) { container.style.position = null; }
			}, 2000);

		} catch ( e ) { console.log('Exception in "scrollToElement" function:', e); }
	}


	extractNumbers( value: any ): number {
		if ( !value ) { return 0; }

		let number = value;
		if ( isNaN(value) ) {
			// Extract digits, minus sign and decimal
			number = value.toString().replace(/[^\d.-]/g, '');
		}

		return Number(number);
	}


	// Helper for templates
	parseFloat( number ): number {
		return parseFloat( number );
	}


	// Converts local file to base64
	/* Usage
		this.getFileData( file, 'base64' )
		.then( imageData => { console.log('IMAGE:', imageData); })
		.catch( error => { console.log('Error: ', error); });
	*/
	getFileData( file: any, type: string = 'base64' ): Promise<any> {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();

			switch (type) {
				default:
				case 'base64'      : reader.readAsDataURL(file); break;
				case 'arrayBuffer' : reader.readAsArrayBuffer(file); break;
				case 'binaryString': reader.readAsBinaryString(file); break; 	// Experimental
				case 'text'        : reader.readAsText(file); break;
			}
			reader.onload  = ()    => resolve(reader.result);
			reader.onerror = error => reject(error);
		});
	}


	// Convert base64 To Blob (without losing data)
	// Copied from https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
	b64toBlob( b64Data, contentType = '', sliceSize = 512 ): Blob {
		const byteCharacters = atob(b64Data);
		const byteArrays = [];

		for ( let offset = 0; offset < byteCharacters.length; offset += sliceSize ) {
			const slice = byteCharacters.slice(offset, offset + sliceSize);

			const byteNumbers = new Array( slice.length );
			for ( let i = 0; i < slice.length; i++ ) {
				byteNumbers[i] = slice.charCodeAt( i );
			}

			const byteArray = new Uint8Array(byteNumbers);

			byteArrays.push(byteArray);
		}

		const blob = new Blob(byteArrays, { type: contentType });
		return blob;
	}


	// Add space after comma 'abc,abc' => 'abc, abc'
	commaSeparate( string: string ): string {
		if ( !string ) { return ''; }

		return string.split(/\s*,\s*/).join(', ');
	}


	// Capitalize first letter
	capitalize( str: string ): string {
		if ( !str ) { return ''; }

		return str.charAt(0).toUpperCase() + str.slice(1);
	}


	returnDeepCopy<T>( item: T ): T {
		// Deep copy - otherwise arrays are still reference copied;
		// NOTE: JSON.parse( JSON.stringify() ) messes up date values
		return this.clonerService.deepClone<T>( item );
	}


	// Removes Previous Errors
	clearPreviousErrors( containerName = null, evenNonDismissible = false ): void {
		if ( containerName ) {
			this.alertService.closeAllContainerAlerts( containerName, evenNonDismissible );
		} else {
			this.alertService.closeAllAlerts( evenNonDismissible );
		}
	}


	// Generate Error message
	/*
		Params:
		message      : Error message
		containerName: Alert container to use, null means global
		timeout      : Number of milliseconds to hide, 0 means stay indefinite
	*/
	handleError(
		message: string,
		{ containerName = null, timeout = 0, clearPrevious = true, dismissible = true }:
		{ containerName?: string, timeout? : number, clearPrevious? : boolean, dismissible?: boolean } = {}
	): void {

		if ( clearPrevious ) { this.clearPreviousErrors( containerName ); }
		if ( containerName ) {
			this.alertService.addAlert( new AlertModel( 'danger', message, timeout, dismissible), containerName );
		} else {
			this.alertService.addAlert( new AlertModel( 'danger', message, timeout, dismissible ) );
		}
	}


	// Generate Success message
	/*
		Params:
		message      : Success message
		containerName: Alert container to use, null means global
		timeout      : Number of milliseconds to hide, 0 means stay indefinite
	*/
	handleSuccess(
		message: string,
		{ containerName = null, timeout = 0, clearPrevious = true, dismissible = true }:
		{ containerName?: string, timeout? : number, clearPrevious? : boolean, dismissible?: boolean } = {}
	): void {

		if ( clearPrevious ) { this.clearPreviousErrors( containerName ); }
		if ( containerName ) {
			this.alertService.addAlert( new AlertModel( 'success', message, timeout, dismissible ), containerName );
		} else {
			this.alertService.addAlert( new AlertModel( 'success', message, timeout, dismissible ) );
		}
	}


	// Generate Warning message
	/*
		Params:
		message      : Warning message
		containerName: Alert container to use, null means global
		timeout      : Number of milliseconds to hide, 0 means stay indefinite
	*/
	handleWarning(
		message: string,
		{ containerName = null, timeout = 0, clearPrevious = true, dismissible = true }:
		{ containerName?: string, timeout?: number, clearPrevious?: boolean, dismissible?: boolean } = {}
	): void {

		if ( clearPrevious ) { this.clearPreviousErrors( containerName ); }
		if ( containerName ) {
			this.alertService.addAlert( new AlertModel( 'warning', message, timeout, dismissible ), containerName );
		} else {
			this.alertService.addAlert( new AlertModel( 'warning', message, timeout, dismissible ) );
		}
	}


	// Generate Info message
	/*
		Params:
		message      : Info message
		containerName: Alert container to use, null means global
		timeout      : Number of milliseconds to hide, 0 means stay indefinite
	*/
	handleInfo(
		message: string,
		{ containerName = null, timeout = 0, clearPrevious = true, dismissible = true }:
		{ containerName?: string, timeout?: number, clearPrevious?: boolean, dismissible?: boolean } = {}
	): void {

		if ( clearPrevious ) { this.clearPreviousErrors( containerName ); }
		if ( containerName ) {
			this.alertService.addAlert( new AlertModel( 'info', message, timeout, dismissible ), containerName );
		} else {
			this.alertService.addAlert( new AlertModel( 'info', message, timeout, dismissible ) );
		}
	}


	showSnackBar<T>( message: string | TemplateRef<T>, actionButton?: string | TemplateRef<T>, mode: SnackBarMode = 'success', config?: ISnackBarConfig ): MatSnackBarRef<SnackbarContainerComponent> {
		return this.buildSnackbar( message, actionButton, mode, config );
	}


	showSimpleSnackBar<T>( message: string | TemplateRef<T>, config?: ISnackBarConfig ): MatSnackBarRef<SnackbarContainerComponent> {
		return this.buildSnackbar( message, undefined, 'success', config );
	}


	hideSnackbar(): void {
		this.snackBarService.dismiss();
	}


	// Generate A mat Snackbar with success/error message
	private buildSnackbar<T>( message: string | TemplateRef<T>, actionButton?: string | TemplateRef<T>, mode?: SnackBarMode, config?: ISnackBarConfig ): MatSnackBarRef<SnackbarContainerComponent> {
		const panelClasses = !!config?.panelClasses?.length ? config.panelClasses : ( !actionButton ? ['text-center'] : '' ); // If there no action button default center align
		const data: IMatSnackBarData<T> = { message, actionButton, mode };
		const configuration: MatSnackBarConfig = {
			duration  : config?.duration === undefined ? 4000 : config.duration,
			data      : data,
			panelClass: [ 'mat-snackbar-panel-custom', `mode-${mode}`, 'color-white', ...panelClasses], // To customize the panel if needed, eg in error mode panel would have classes like "mat-snackbar-panel-custom mode-error"
		};

		return this.snackBarService.openFromComponent( SnackbarContainerComponent, configuration );
	}


	getProposalAdjustedStatus( status: ProposalStatusEnum ): string {
		switch ( status ) {
			case ProposalStatusEnum.Draft:
				return 'Draft';

			case ProposalStatusEnum.Submitted:
				return 'Submitted';

			case ProposalStatusEnum.Viewed:
				return 'Viewed By Client';

			case ProposalStatusEnum.Accepted:
				return 'Accepted';

			case ProposalStatusEnum.Declined:
				return 'Declined';

			case ProposalStatusEnum.Expired:
				return 'Expired';

			case ProposalStatusEnum.ChangesRequired:
			case ProposalStatusEnum.Delayed:
				return 'Changes Required';

			case ProposalStatusEnum.Completed:
				return 'Completed';
		}

		return status;
	}


	// Note: Date( 'YYYY-MM-DD' ) uses timezone based date BUT Date( 'YYYY/MM/DD' ) doesn't
	static getDateWithoutTimezone( dateToConvert: string ): Date {
		// Capture base date value by splitting it on 'T' - then convert 2022-12-20 to 2022/12/20
		const _d = dateToConvert?.split('T')?.[0]?.replace(/\-/g, '/');	// Using regex to replace "all" '-' with '/'

		if ( _d ) {
			return new Date( _d );
		}

		return null;
	}


	// Return first item if found
	findItemByKey<T, K extends keyof T>( items: T[], itemId: unknown, key: K ): T {
		return items.find( ( item: T ) => item && item[key] === itemId );
	}


	// Return first item index if found
	findItemIndexByKey<T, K extends keyof T>( items: T[], itemId: unknown, key: K ): number {
		return items.findIndex( ( item: T ) => item && item[key] === itemId );
	}


	// Check if all the properties of an object are empty
	checkIfObjectEmpty( object ): boolean {
		if ( !object ) { return true; }
		const isEmpty = Object.values( object ).every( x => ( x === null || x === '' || x === undefined ) );

		return isEmpty;
	}


	logError( e: any, context = null, metaData = null ): void {
		if ( environment.name !== 'local' ) {
			if ( bugsnagClient ) {
				bugsnagClient.setContext( context );
				bugsnagClient.addMetadata( 'meta', metaData );
				bugsnagClient.notify( e );
			}
		}

		console.log( `Exception sent to BugSnag: `, e );
	}


	exceptionLog( e ): void {
		if ( e?.message ) {
			console.log( e.message );
		} else {
			console.log( e );
		}
	}


	isValueDefined( value: boolean | number | string | undefined | null | Date ): boolean {
		return value !== undefined && value !== null;
	}


	getTransactionAdjustedStatus( status: TransactionStatusEnum ): string {
		if ( status ) {
			switch ( status ) {
				case TransactionStatusEnum.Draft:
					return 'Draft';

				case TransactionStatusEnum.Paid:
					return 'Paid';

				case TransactionStatusEnum.Pending:
					return 'Processing';

				case TransactionStatusEnum.Verifying:
					return 'Verifying';

				case TransactionStatusEnum.Submitted:
					return 'Submitted';

				case TransactionStatusEnum.Viewed:
					return 'Viewed';

				case TransactionStatusEnum.Declined:
					return 'Declined';
			}
		}

		return status;
	}


	getTransactionDotColor( status: TransactionStatusEnum ): string {
		if ( status ) {
			switch ( status ) {
				case TransactionStatusEnum.Draft:
					return 'grey';

				case TransactionStatusEnum.Paid:
				case TransactionStatusEnum.Pending:
					return 'green';

				case TransactionStatusEnum.Verifying:
				case TransactionStatusEnum.Viewed:
				case TransactionStatusEnum.Submitted:
					return 'yellow';

				case TransactionStatusEnum.Declined:
					return 'red';
			}
		}

		return 'blank';
	}


	getPaymentGatewayText( gateway: GatewayTypeEnum ): string {
		if ( !gateway ) { return null; }

		switch ( gateway ) {
			case GatewayTypeEnum.Stripe:
				return 'Stripe';
		}

		return gateway;
	}


	getChangeOrderItemActionType( action: ChangeOrderItemActionEnum ): string {
		if ( action ) {
			switch ( action ) {
				case ChangeOrderItemActionEnum.Added:
					return 'Item Added';

				case ChangeOrderItemActionEnum.Refunded:
					return 'Item Refunded';

				case ChangeOrderItemActionEnum.Replaced:
					return 'Item Replaced';

				case ChangeOrderItemActionEnum.PriceChanged:
					return 'Price Changed';

				case ChangeOrderItemActionEnum.PriceDecreased:
					return 'Price Decreased';

				case ChangeOrderItemActionEnum.PriceIncreased:
					return 'Price Increased';

				case ChangeOrderItemActionEnum.QtyDecreased:
					return 'Quantity Decreased';

				case ChangeOrderItemActionEnum.QtyIncreased:
					return 'Quantity Increased';

				case ChangeOrderItemActionEnum.Changed:
					return 'Changed';

				case ChangeOrderItemActionEnum.MiscChanges:
					return 'Misc Changes';
			}
		}

		return action;
	}


	changeFavicon( iconName: string ): void {
		if ( !iconName ) { iconName = 'favicon.ico'; }

		const link: HTMLLinkElement = document?.querySelector( '#proposalviewer-application-favicon' );
		if ( link ) {
			link.href = `assets/${iconName}`;
		}
	}
}
