<section class="section position-relative proposal-room" [class.simple-view]="isSimpleView">
	<app-loader *ngIf="loaderStates?.area"></app-loader>
	<header>
		<div class="inner-header">
			<div class="row align-items-center">
				<div class="col-12">
					<div class="inner-header-wrap">
						<div class="column-1">
							<h3 class="heading">
								<span class="area-title h5 std no-margin" [class.with-options]="area?.options?.length > 1">{{ area?.name }}</span>
							</h3>
						</div>

						<div class="column-2" *ngIf="area?.options?.length > 1">
							<div class="button-group option-buttons" btnRadioGroup [(ngModel)]="radioModel" [ngModelOptions]="{standalone: true}">
								<label class="_button _smaller" *ngIf="area?.options?.length > 1" btnRadio="0" tabindex="0" (click)="selectOptionTab(0, area?.options[0].id)">Option 1</label>
								<label class="_button _smaller" *ngIf="area?.options?.length > 1" btnRadio="1" tabindex="0" (click)="selectOptionTab(1, area?.options[1].id)">Option 2</label>
								<label class="_button _smaller" *ngIf="area?.options?.length > 2" btnRadio="2" tabindex="0" (click)="selectOptionTab(2, area?.options[2].id)">Option 3</label>
							</div>
						</div>

						<div class="column-3" *ngIf="!isPreview && area?.options?.length > 1">
							<ng-container>
								<div *ngIf="states?.confirmingOptions" class="confirming">
									<span style="margin-right: 10px">You're <b>declining {{ decliningOptionsString }}.</b> Are you sure?</span>
									<app-br class="d-md-none" />
									<button class="_button _green _smaller f12 fw-bold modified-button" (click)="confirmOption()">Confirm</button>
									<button class="_button _blank _smaller f12 fw-bold modified-button" (click)="cancelConfirmingOption()">Cancel</button>
								</div>

								<ng-container *ngIf="!states?.confirmingOptions">
									<button class="_button _green _smaller f12 fw-bold modified-button" *ngIf="!clientSelectedOption" (click)="confirmingOption()">Choose This Option</button>

									<div *ngIf="clientSelectedOption" class="confirmed">
										<i class="fas fa-check-circle icon"></i>
										<b>Option {{ clientSelectedOption }} chosen</b>
										<button class="_button _blank _smaller" *ngIf="proposalStatus !== ProposalStatusEnum.Accepted && proposalStatus !== ProposalStatusEnum.Completed" (click)="undo()">undo</button>
									</div>
								</ng-container>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
	<div class="section-body f13" [class.overflow-hidden]="activeOptionStatus === AreaOptionStatusEnum.Declined">

		<tabset #optionalItemTabs>
			<tab *ngFor="let option of area?.options; let i = index" [heading]="'' + option?.id" (selectTab)="onSelectTab( i )">
				<ng-container *ngIf="option?.status === AreaOptionStatusEnum.Declined">
					<div class="declined-backdrop"></div>
					<div class="declined-stamp">
						<div class="date-line">
							<span>THIS OPTION HAS BEEN</span>
							<span class="text-end">{{ option?.clientLastDecisionDate | date:'M/dd/yyyy' }} </span>
						</div>
						<h1>DECLINED</h1>
					</div>
				</ng-container>

				<div *ngIf="area?.options[i]?.description" class="position-relative description-container">
					<div class="pre-line" [froalaView]="area?.options[i]?.description"></div>
					<app-br class="grid-fixed" />
				</div>

				<div class="table-item-header">
					<app-subtle-label class="g-items gray">Items</app-subtle-label>
					<ng-container *ngIf="allItems?.length > 0">
						<app-subtle-label class="g-sell gray" *ngIf="!clientSettings?.hideItemPrices">Sell Price</app-subtle-label>
						<app-subtle-label class="g-qty gray">Qty</app-subtle-label>
						<app-subtle-label class="g-total gray d-none d-md-block" *ngIf="!clientSettings?.hideItemPrices">Total<ng-container *ngIf="!isSimpleView">&nbsp;</ng-container></app-subtle-label>
						<app-subtle-label class="g-total gray d-none d-md-block" *ngIf="clientSettings?.hideItemPrices"><ng-container *ngIf="!isSimpleView">&nbsp;</ng-container></app-subtle-label>
					</ng-container>
				</div>

				<div>
					@for ( itemIdType of getOptionParentItemIdType( option?.id ); track itemIdType?.id ) {
						<ng-container *ngTemplateOutlet="proposalRoomItemTmpl; context: { itemIdType: itemIdType }" />
					}

					@for ( dataModel of getCheckedChangeOrderMiscItems( option?.id ); track dataModel?.changeOrderId; let firstItem = $first ) {
						@if ( dataModel?.items ) {
							<div class="misc-changes-bucket change-order-{{ dataModel?.changeOrderNumber }}-background-color">
								@for ( itemIdType of getMiscItemsOptionParentItemIdType( dataModel?.items ); track itemIdType?.id; let firstItem = $first ) {
									<ng-container *ngTemplateOutlet="proposalRoomItemTmpl; context: { itemIdType: itemIdType, showMiscLabel: firstItem }" />
								}
							</div>
						}
					}

					<ng-template #proposalRoomItemTmpl [typedTemplate]="proposalRoomItemTokens" let-itemIdType="itemIdType" let-showMiscLabel="showMiscLabel">
						<app-proposal-room-item *ngIf="getItem( itemIdType?.id ) as item"
							[showMiscLabel]  = "showMiscLabel"
							[item]           = "item"
							[areaId]         = "area?.id"
							[areaOptionId]   = "option?.id"
							[currency]       = "currency"
							[clientSettings] = "clientSettings"
						>
							<app-proposal-room-nest
								[hideItemPrices] = "clientSettings?.hideItemPrices || clientSettings?.hideLaborRateQtyTotal"
								[hideImages]     = "clientSettings?.hideImages"
								[combinePrice]   = "item?.isCombinePrice"
								[currency]       = "currency"
								[parentId]       = "item?.id"
								[showItems]      = "proposalCommonService?.getNestedAreasState( item?.id ) | pipeFunction: getNestedAreasState"
							>
								@for ( nestedItem of getNestedItems( item?.id ); track nestedItem?.id ) {
									<app-proposal-room-item
										[item]             = "nestedItem"
										[nested]           = "true"
										[areaId]           = "area?.id"
										[areaOptionId]     = "option?.id"
										[currency]         = "currency"
										[clientSettings]   = "clientSettings"
										[isCombinedParent] = "item?.isCombinePrice"
									/>
								}
							</app-proposal-room-nest>
						</app-proposal-room-item>
					</ng-template>
				</div>
			</tab>
		</tabset>

	</div>

	<footer *ngIf="!clientSettings?.hideAreaTotals">
		<div class="footer-wrap">
			<div class="text-end">
				<h3 *ngIf="showAreaTotal" class="heading" style="font-weight: 700;">{{ area?.name | truncate: 30 }} Total &nbsp;&nbsp; {{ optionTotalCalculatedAmount | currencyFormat:currency?.format:currency?.symbol }}<ng-container *ngIf="!isSimpleView">&nbsp;&nbsp;</ng-container></h3>
				@if ( !showAreaTotal && optionRecurringServiceCalculatedTotal > 0 ) {
					<ng-container *ngTemplateOutlet="recurringTotalTmpl; context: { total: optionTotalCalculatedAmount, recurringTotal: optionRecurringServiceCalculatedTotal }"></ng-container>
				}
			</div>
		</div>

		<div class="row" *ngIf="!clientSettings?.hideAreaTotals && showAreaTotal && optionRecurringServiceCalculatedTotal > 0">
			<div class="col-12 text-end">
				<app-br class="small-grid-fixed" />
				<ng-container *ngTemplateOutlet="recurringTotalTmpl; context: { total: optionTotalCalculatedAmount, recurringTotal: optionRecurringServiceCalculatedTotal }"></ng-container>
			</div>
		</div>

		<ng-template #recurringTotalTmpl let-total="total" let-recurringTotal="recurringTotal">
			<div class="recurring-item"><span *ngIf="total > 0">Plus</span> {{ recurringTotal | currencyFormat:currency?.format:currency?.symbol }} in Recurring Services</div>
		</ng-template>
	</footer>
</section>
