
import { Injectable, inject } from '@angular/core';

// Utilities
import { Utilities } from '@app/shared/utilities';


@Injectable()
export class ProposalCommonService {
	private readonly utils = inject( Utilities );

	private readonly nestedAreasState = new Map<number, boolean>();		// Key itemId, value state


	setNestedAreasState( itemId: number, state: boolean ): void {
		this.nestedAreasState.set( itemId, state );
	}


	getNestedAreasState( itemId: number ): boolean {
		return this.nestedAreasState.get( itemId );
	}

}
