import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from './utilities';	// TODO: UPDATE TO SHARED UTILITIES


/*
	Sets currency in correct formatting

	Example:
	{{ 123456789 | currencyFormat: 'LN333,.2F', '$' }} -> $1,234,567.89
*/
@Pipe({
	name: 'currencyFormat',
	standalone: true,
})
export class CurrencyFormatPipe implements PipeTransform {

	transform( value: number | string, format: string = 'LN333,.2F', symbol: string = '$' ): string | number {
		// Convert string to value
		const _val = typeof value === 'string' ? +value : value;

		if ( _val === null || _val === undefined || isNaN( _val ) ) {
			return null;
		}

		return Utilities.formatCurrency( _val, format, symbol ) ;
	}

}
