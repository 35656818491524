<div bsModal #modal="bs-modal" (onHidden)="hideModal()" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down black-modal modal-lg footer-stick height-standard">
		<div class="modal-content">
			<div class="modal-header justify-content-center">
				<button type="button" class="close" aria-label="Close" (click)="submitComment()"><span aria-hidden="true">&times;</span></button>
				<h5 class="modal-title">&nbsp;</h5>
			</div>
			<div class="modal-body">
				<div class="row"><div class="col-12"><app-alert-container [containerName]="alertContainer"></app-alert-container></div></div>
				<app-loader *ngIf="loader" [circle]="true" class="position-fixed"></app-loader>

				<div class="text-center" *ngIf="!payLater">
					<i class="fad fa-check-circle success-icon"></i>
					<app-br class="big-grid-fixed" />
					<h2 class="std color-black">Proposal Accepted</h2>
					<app-br class="small-grid-fixed" />
					<p>Thanks for choosing us to complete your project!<br />We look forward to working with you.</p>

					<section class="container-section" [class.payment-data]="payment">
						<div class="whats-next-section" *ngIf="!addNote">
							<header>WHAT'S NEXT</header>
							<ng-container *ngIf="!payment">
								<h2>We'll be in touch</h2>
								<p>{{ salesPerson }} has been notified that you accepted.<br />We will reach out to you soon with next steps.</p>
							</ng-container>
							<ng-container *ngIf="payment">
								<h2>Make a Payment</h2>
								<p>A payment of {{ payment?.amount | currencyFormat:currency?.format:currency?.symbol }} is required to move forward. {{ paymentOptionsText }}</p>
							</ng-container>
						</div>

						<ng-container *ngIf="addNote">
							<div class="form-field comment-container">
								<label>Note for {{ salesPerson }}</label>
								<textarea [(ngModel)]="comment" placeholder=""></textarea>
							</div>
							<app-br class="big-grid-fixed" />
							<app-br class="grid-fixed" />
						</ng-container>
					</section>

					<ng-container *ngIf="!payment">
						<ng-container *ngIf="!addNote">
							<button (click)="showNoteField()" class="_button _fixed-medium _blank"><i class="far fa-pencil"></i>&nbsp; Add a note</button>
							<app-br class="big-grid-fixed" />
						</ng-container>
						<button *ngIf="!comment?.length" (click)="submitComment()" class="_button _fixed-medium _green fw-bold f15">Done</button>
						<button *ngIf="comment?.length" (click)="submitComment()" class="_button _fixed-medium _green fw-bold f15">Submit</button>
					</ng-container>

					<ng-container *ngIf="payment">
						<a [href]="payment?.paymentUrl" (click)="makePaymentButtonClicked()" target="_blank" class="_button _fixed-large _green _no-side-padding fw-bold f15">
							<ng-container *ngIf="isPaymentProcessingEnabled">Make a Payment</ng-container>
							<ng-container *ngIf="!isPaymentProcessingEnabled">View Payment Request</ng-container>
						</a>
						<ng-container *ngIf="!addNote">
							<app-br class="grid-fixed" />
							<button (click)="showPayLater()" class="_button _fixed-medium _blank">I'll pay later</button>
						</ng-container>
					</ng-container>

				</div>


				<div class="text-center" *ngIf="payLater">
					<app-br class="bigger-grid-fixed" />
					<app-br class="big-grid-fixed" />
					<h2 class="std color-black">Pay later, no problem.</h2>
					<app-br class="grid-fixed" />

					<section class="container-section" [class.payment-data]="payment">
						<p>We'd like to get things started as soon as possible for you, but we understand if you need some more time. We've emailed you a link to pay later. If you'd like to pay another way, just let us know!</p>

						<app-br class="big-grid-fixed" />

						<div class="form-field comment-container">
							<label>Optional note for {{ salesPerson }}</label>
							<textarea [(ngModel)]="comment" placeholder=""></textarea>
						</div>
						<app-br class="big-grid-fixed" />
						<app-br class="grid-fixed" />
					</section>

					<button (click)="submitComment()" class="_button _fixed-medium _green fw-bold f15">Done</button>
				</div>
			</div>
		</div>
	</div>
</div>
