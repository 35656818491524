import { formatNumber } from '@angular/common';


export class Utilities {


	// Based on spec from https://docs.google.com/spreadsheets/d/1PnRXHjk47f29svYmR8Kkp5xmlW5v1126Is9FWTiSdi8/edit#gid=0
	static formatCurrency( value: number, format: string = 'LN333,.2F', symbol: string = '$' ): string | number {
		if ( format ) {
			let output: string = '';

			const symbolPosition      = format[0];
			const symbolSpacing       = format[1];
			const groupingOne         = format[2];
			const groupingTwo         = format[3];	// Not implemented as yet
			const groupingThree       = format[4];	// Not implemented as yet
			const groupingSymbol      = format[5]?.replace( 'S', ' ' )?.replace( 'N', '' );
			const decimalSymbol       = format[6]?.replace( 'S', ' ' )?.replace( 'N', '' );
			const numberDecimalPlaces = format[7];
			const decimalForced       = format[8];

			// Fractional part - if Its Forced (F) use `numberDecimalPlaces` otherwise 0
			const minIntegerDigits  = 1;
			const minFractionDigits = decimalForced?.toUpperCase() === 'F' ? +numberDecimalPlaces : 0;
			const maxFractionDigits = +numberDecimalPlaces;

			const numberSection = Utilities.groupNumber( value, +groupingOne, groupingSymbol, decimalSymbol, minIntegerDigits, minFractionDigits, maxFractionDigits );
			const symbolSpace   = symbolSpacing?.toUpperCase() === 'S' ? ' ' : '';

			if ( symbol ) {
				if ( symbolPosition?.toUpperCase() === 'L' ) {
					output += `${ symbol }${ symbolSpace }${ numberSection }`;
				} else {
					// If symbol is on right - there should be a space between number part and symbol
					output += `${ numberSection }${ symbolSpace }${ symbol }`;
				}
			}

			return output;
		}

		return value;
	}


	// Add comma/group symbol - decimal symbol and separate numbers into groups
	static groupNumber( value: number, groupAfter: number = 3, groupSymbol: string = ',', decimalSymbol: string = '.', minIntegerDigits: number = 1, minFractionDigits: number = 0, maxFractionDigits: number = 5 ): string {
		// Use Angular's formatNumber to generate US based standard string to extract decimal digits and rounding
		const strValue = formatNumber( value, 'en-US', `${minIntegerDigits}.${minFractionDigits}-${maxFractionDigits}` );

		const str = strValue?.split('.');

		// Remove all commas (if any) in non-fractional part
		str[0] = str?.[0]?.replace(/,/g, '');

		// Non-fractional part
		if ( str?.[0]?.length >= ( groupAfter + 1 ) ) {
			if ( groupAfter > 0 ) {
				str[0] = str[0].replace( new RegExp( `\(\\d\)\(\?\=\(\\d\{${groupAfter}\}\)\+\$\)`, 'g' ), `$1${groupSymbol}` );
			}
		}

		// Fractional part
		if ( str?.[1]?.length >= ( groupAfter + 1 ) ) {
			str[1] = str[1].replace( new RegExp( `\(\\d\{${groupAfter}\}\)`, 'g' ), '$1' );
		}

		return str.join( decimalSymbol );
	}


}
