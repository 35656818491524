import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, inject, OnInit, ChangeDetectorRef } from '@angular/core';

// Utility
import { Utilities } from '@app/shared/utilities';

// Services
import { ChangeOrderService } from '@app/proposals/services/change-order.service';

// Models
import {
	IProposalClientSettingsModel,
	IProposalAreaItemModel,
	IProposalAreaLaborModel,
	IProposalAreaCustomItemModel,
	IProposalItemChangeOrderAction,
} from '@app/core/models/proposal.models';
import { ICurrencyModel } from '@app/core/models/common.models';

// Enums
import { AreaItemTypeEnum, ChangeOrderItemActionEnum, ProposalStatusEnum } from '@app/core/enums';


@Component({
	selector       : 'app-proposal-room-item',
	templateUrl    : './proposal-room-item.component.html',
	styleUrls      : ['./proposal-room-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalRoomItemComponent implements OnInit, OnChanges {
	private readonly cd                = inject( ChangeDetectorRef );
	public readonly changeOrderService = inject( ChangeOrderService );
	public readonly utils              = inject( Utilities );

	@Input() showMiscLabel   : boolean                    = false;
	@Input() item            : IProposalAreaItemModel     = null;
	@Input() areaId          : number                     = null;
	@Input() areaOptionId    : number                     = null;
	@Input() nested          : boolean                    = false; // Tells if item is nested itself
	@Input() isCombinedParent: boolean                    = false;
	@Input() currency        : ICurrencyModel             = null;
	@Input() clientSettings  : IProposalClientSettingsModel;

	readonly AreaItemTypeEnum          = AreaItemTypeEnum;	// Needed here for Template
	readonly ChangeOrderItemActionEnum = ChangeOrderItemActionEnum;
	readonly ProposalStatusEnum        = ProposalStatusEnum;

	triggerValue: number = 0;

	type: AreaItemTypeEnum;

	public totalPrice      : number;		// Used by nested components
	public recurringService: boolean;

	readonly lineItemTokens: {
		item             : IProposalAreaItemModel;
		isOriginalItem   : boolean;
		isChangeOrderItem: boolean;
	};


	ngOnInit(): void {
		this.changeOrderService.changeTrigger$.subscribe( ( triggerValue ) => {
			this.triggerValue = triggerValue;

			this.markForChange();
		});
	}


	ngOnChanges( changes: SimpleChanges ): void {
		if ( changes?.['item'] ) {
			this.type = this.item.type;

			this.totalPrice = this.item?.totalPrice;

			switch ( this.type ) {
				case AreaItemTypeEnum.Labor:
				case AreaItemTypeEnum.Custom:
					const _item = this.item as ( IProposalAreaCustomItemModel | IProposalAreaLaborModel );
					this.recurringService = !!_item.isRecurringService;
					break;
			}
		}
	}


	get isValidType(): boolean {
		return ( this.type === AreaItemTypeEnum.Part || this.type === AreaItemTypeEnum.Labor || this.type === AreaItemTypeEnum.Custom );
	}


	// Consolidate multiple actions of same change order into single pill
	consolidateChangeOrderTags(): {id: number, number: number, actions: ChangeOrderItemActionEnum[] }[] {
		const consolidatedArray: {id: number, number: number, actions: ChangeOrderItemActionEnum[] }[] = [];

		if ( this.item?.changeOrderActions ) {
			for ( const item of this.item?.changeOrderActions ) {
				if ( this.changeOrderService.showChangeOrderData( item?.id ) ) {
					// Only check for change orders that should be shown (highlighted)
					// Loop through each change order tag - consolidate its actions
					const foundIndex = consolidatedArray.findIndex( el => el?.id === item?.id );
					if ( foundIndex === -1 ) {
						if ( item?.action ) {
							consolidatedArray.push({
								id     : item?.id,
								number : item?.number,
								actions: [ item?.action ],
							});
						}
					} else {
						// Just push action to already existing change order tag
						if ( item?.action ) {
							consolidatedArray?.[foundIndex]?.actions?.push( item?.action );
						}
					}
				}
			}
		}

		return consolidatedArray;

		/*
		FROM:
			{ id: 1, number: 2, action: ChangeOrderItemActionEnum.Replaced },
			{ id: 1, number: 2, action: ChangeOrderItemActionEnum.QtyIncreased },

		TO:
			{ id: 1, number: 2, actions: [ ChangeOrderItemActionEnum.Replaced, ChangeOrderItemActionEnum.QtyIncreased ] },
		*/
	}


	get changeOrderTagsBeingShown(): IProposalItemChangeOrderAction[] {
		return this.item?.changeOrderActions?.filter( coTag => this.changeOrderService.showChangeOrderData( coTag?.id ) ) || [];
	}


	// Get first checked number for change order color
	get changeOrderNumber(): number {
		const checkedChangeOrderNumbers: number[] = this.changeOrderTagsBeingShown.map( coTag => coTag?.number ) || [];

		return checkedChangeOrderNumbers?.[0];
	}


	get showChangeOrderData(): boolean {
		return this.item?.changeOrderActions?.some( coTag => this.changeOrderService.showChangeOrderData( coTag?.id ) );
	}


	// Get all change order items that are being shown (checked)
	get changeOrderItems(): IProposalAreaItemModel[] {
		const checkedChangeOrderIds: number[] = this.changeOrderTagsBeingShown.map( coTag => coTag?.id ) || [];
		if ( !checkedChangeOrderIds?.length ) { return []; }

		const items = [];
		checkedChangeOrderIds?.forEach( changeOrderId => {
			items.push( ...this.changeOrderService.getChangeOrderItems( this.areaId, this.areaOptionId, changeOrderId, this.item?.id ) || [] );
		});

		return items;
	}


	private markForChange(): void {
		this.cd.markForCheck();
	}

}
