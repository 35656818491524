import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';

// Services
import { BaseApiService } from '@app/core/services/baseapi.service';
import { SessionStorageService } from '@app/core/services/sessionstorage.service';

// Models
import { ErrorModel } from '@app/core/models/error.model';

// Constants
import { SessionStorageKeys } from '@app/shared/constants';


@Injectable({
	providedIn: 'root',
})
export class CommonService extends BaseApiService {

	// Some global variables
	readonly globals = {
		proposalPusherConnected: false,
	};


	// Observables
	readonly proposalDetail$ = new Subject<{ id: number, deleted: boolean }>();


	constructor(
		private _injector     : Injector,
		private sessionStorage: SessionStorageService,
	) {
		super( _injector );
	}


	// Emit Proposal Detail
	proposalDetail( proposalId: number, deleted = false ) {
		this.proposalDetail$.next( { id: proposalId, deleted: deleted } );
	}


	getProposalPusherConnected() {
		return this.globals.proposalPusherConnected;
	}


	setProposalPusherConnected( value: boolean ) {
		this.globals.proposalPusherConnected = value;
	}


	getErrorText( error: ErrorModel ): string {
		if ( error.code && typeof error.code === 'string' ) {
			switch ( error.code.toUpperCase() ) {
				case 'PRECONDITIONFAILED':
					return 'Please refresh your browser';

				case 'UNAUTHORIZEDACCESSEXCEPTION':
					return 'You are not authorized to view this proposal';
			}
		}

		return error.message;
	}


	storeDownloadProcessId( processId: string ): void {
		if ( !processId ) { return; }

		// Store process Id in session storage so download is only triggered in this browser tab
		const randomKeySuffix = parseInt( String( Math.random() * 1e11 ) );
		this.sessionStorage.store( `${SessionStorageKeys.DownloadProcessIdKey}-${randomKeySuffix}`, processId, 60 * 60 * 1 );	// 1 hour expiry
	}


	downloadFile( filePath: string, processKey: string = null ): void {
		if ( filePath ) {
			try {
				const anchor = document.createElement('a');
				if ( anchor ) {
					anchor.href = filePath;
					document.body.appendChild( anchor );
					anchor?.click();
					document.body.removeChild( anchor );
				}
			} catch ( error ) {
				// Backup method - this method causes issues if browser is closed and reopened to load previous session
				window?.location?.assign( filePath );
				console.error( error );
			}
		}

		if ( processKey ) {
			this.sessionStorage.remove( processKey );
		}
	}


	processIdExists( processId: string ): boolean|string {
		if ( !processId ) { return false; }

		for ( const key of this.sessionStorage.getKeys() ) {
			if ( key?.includes( SessionStorageKeys.DownloadProcessIdKey ) ) {
				// Retrieve processId from session storage
				const storedProcessId = this.sessionStorage.retrieve( key );

				// Check if processId exists in session storage for this browser tab
				if ( storedProcessId && processId === storedProcessId ) {
					// Process Id matches, return the session storage key
					return key;
				}
			}

		}

		return false;
	}

}
