import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Components
import { AppComponent } from '@app/app.component';

// Modules
import { AppRoutingModule } from '@app/app-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { CoreModule } from '@app/core/core.module';

// 3rd Party
import { SignaturePadModule } from '@viablelogic/ngx-signature-pad';

// Components
import { ProposalDetailComponent } from '@app/proposals/proposal-detail/proposal-detail.component';
import { ProposalRoomComponent } from '@app/proposals/proposal-room/proposal-room.component';
import { ProposalRoomItemComponent } from '@app/proposals/proposal-room-item/proposal-room-item.component';
import { ProposalRoomNestComponent } from '@app/proposals/proposal-room-nest/proposal-room-nest.component';
import { FroalaViewModule } from 'angular-froala-wysiwyg';
import { AcceptProposalModalComponent } from '@app/proposals/modal-accept-proposal/modal-accept-proposal.component';
import { PendingOptionsModalComponent } from '@app/proposals/modal-pending-options/modal-pending-options.component';
import { RequestChangesModalComponent } from '@app/proposals/modal-request-changes/modal-request-changes.component';
import { AfterAcceptProposalModalComponent } from '@app/proposals/modal-after-accept-proposal/modal-after-accept-proposal.component';
import { ProposalPaymentScheduleComponent } from '@app/proposals/proposal-payment-schedule/proposal-payment-schedule.component';
import { PaymentRequestComponent } from '@app/proposals/payment-request/payment-request.component';
import { SimpleCoverPageComponent } from '@app/proposals/simple-view-components/cover-page/cover-page.component';
import { FinancialSummaryComponent } from '@app/proposals/financial-summary/financial-summary.component';
import { ChangeOrderListItemComponent } from '@app/proposals/change-order-list-item/change-order-list-item.component';
import { ProposalLineItemComponent } from '@app/proposals/proposal-line-item/proposal-line-item.component';


@NgModule({
	imports: [
		BrowserModule,
		AppRoutingModule,
		SharedModule,
		CoreModule,
		FroalaViewModule.forRoot(),
		SignaturePadModule,
	],
	declarations: [
		AppComponent,
		ProposalDetailComponent,
		ProposalRoomComponent,
		ProposalRoomItemComponent,
		ProposalRoomNestComponent,
		AcceptProposalModalComponent,
		PendingOptionsModalComponent,
		RequestChangesModalComponent,
		AfterAcceptProposalModalComponent,
		ProposalPaymentScheduleComponent,
		PaymentRequestComponent,
		SimpleCoverPageComponent,
		FinancialSummaryComponent,
		ChangeOrderListItemComponent,
		ProposalLineItemComponent,
	],
	exports  : [],
	providers: [],		// Loaded from Core module
	bootstrap: [ AppComponent ],
})
export class AppModule {}
