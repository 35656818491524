<div bsModal #modal="bs-modal" (onHidden)="hideModal()" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down black-modal modal-lg footer-stick height-standard">
		<div class="modal-content">
			<div class="modal-header justify-content-center">
				<button type="button" class="close" aria-label="Close" (click)="modal.hide()"><span aria-hidden="true">&times;</span></button>
				<h5 class="modal-title">&nbsp;</h5>
			</div>
			<div class="modal-body">
				<div class="row"><div class="col-12"><app-alert-container [containerName]="alertContainer"></app-alert-container></div></div>
				<app-loader *ngIf="loader" [circle]="true" class="position-fixed"></app-loader>

				<div class="text-center" *ngIf="!feedbackSubmitted">
					<i class="fad fa-comment-alt-dots feedback-icon"></i>
					<app-br class="big-grid-fixed" />
					<h2 class="std color-black">Request Changes</h2>
					<app-br class="small-grid-fixed" />
					<section class="container-section">
						<p>Not a problem, Let us know what you're thinking.</p>

						<div class="form-field comment-container">
							<label>Feedback for {{ salesPerson }}</label>
							<textarea [(ngModel)]="comment" placeholder=""></textarea>
						</div>
						<app-br class="grid-fixed" />
					</section>

					<button (click)="requestChanges()"  class="_button _primary _fixed-medium _green fw-bold f15">Submit</button>
				</div>


				<div class="text-center" *ngIf="feedbackSubmitted">
					<i class="fad fa-check-circle success-icon"></i>
					<app-br class="big-grid-fixed" />
					<h2 class="std color-black">Feedback Submitted</h2>
					<app-br class="small-grid-fixed" />
					<section class="container-section">
						<p>{{ salesPerson }} has been notified, they will be in touch.</p>

						<app-br class="bigger-grid-fixed" />
						<button (click)="hideModal()"  class="_button _primary _fixed-medium _green fw-bold f15">Done</button>
					</section>

				</div>

			</div>
		</div>
	</div>
</div>
