import { Directive, Input, TemplateRef } from '@angular/core';


/**
 * REF: https://stackoverflow.com/a/68318283/6737470
 * 
 * USAGE:
 * 	<!-- typedTemplate is the directive, typeToken is an object on our component -->
	<ng-template #someTemplate [typedTemplate]="typeToken" let-control="control" let-param="param">
		{{param}}
	</ng-template>


	typeToken: {
		control: FormControl,
		param  : string,
	};

*/
@Directive({
	selector: 'ng-template[typedTemplate]',
})
export class TypedTemplateDirective<T> {

	// How you tell the directive what the type should be
	@Input() typedTemplate: T;


	// The directive gets the template from Angular
	constructor(private contentTemplate: TemplateRef<T>) {}


	// This magic is how we tell Angular the context type for this directive, which then propagates down to the type of the template
	static ngTemplateContextGuard<T>( dir: TypedTemplateDirective<T>, ctx: unknown ): ctx is T {
		return true;
	}
}
