<div class="main-container">
	<main>

		<div class="d-flex align-items-center justify-content-center" style="height: 90vh">
			<div class="text-center color-text" style="max-width: 280px">
				<img src="/assets/images/sad-portal.svg" />
				<app-br class="big-grid-fixed" />
				<div><i>Whoops! Looks like the page you were looking for doesn't exist.</i></div>
			</div>
		</div>

	</main>
</div>