"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deepCopy = exports.cloneTypedArray = exports.cloneRegExp = exports.cloneDate = exports.cloneDataView = exports.cloneArrayBuffer = void 0;
var cloneArrayBuffer_1 = require("./cloneArrayBuffer");
Object.defineProperty(exports, "cloneArrayBuffer", {
  enumerable: true,
  get: function () {
    return cloneArrayBuffer_1.cloneArrayBuffer;
  }
});
var cloneDataView_1 = require("./cloneDataView");
Object.defineProperty(exports, "cloneDataView", {
  enumerable: true,
  get: function () {
    return cloneDataView_1.cloneDataView;
  }
});
var cloneDate_1 = require("./cloneDate");
Object.defineProperty(exports, "cloneDate", {
  enumerable: true,
  get: function () {
    return cloneDate_1.cloneDate;
  }
});
var cloneRegexp_1 = require("./cloneRegexp");
Object.defineProperty(exports, "cloneRegExp", {
  enumerable: true,
  get: function () {
    return cloneRegexp_1.cloneRegExp;
  }
});
var cloneTypedArray_1 = require("./cloneTypedArray");
Object.defineProperty(exports, "cloneTypedArray", {
  enumerable: true,
  get: function () {
    return cloneTypedArray_1.cloneTypedArray;
  }
});
var deepCopy_1 = require("./deepCopy");
Object.defineProperty(exports, "deepCopy", {
  enumerable: true,
  get: function () {
    return deepCopy_1.deepCopy;
  }
});