import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Models
import { IProposalPaymentScheduleItemModel } from '@app/core/models/proposal.models';

// Enums
import { PaymentScheduleItemTypeEnum, PaymentSchedulePercentageBasisEnum, PaymentScheduleMilestoneTypeEnum } from '@app/core/enums';


@Component({
	selector       : 'app-proposal-payment-schedule',
	templateUrl    : './proposal-payment-schedule.component.html',
	styleUrls      : ['./proposal-payment-schedule.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalPaymentScheduleComponent implements OnChanges {

	@Input() scheduledPayments: IProposalPaymentScheduleItemModel[] = [];
	@Input() proposalTotal: number;
	@Input() projectTotal : number;
	@Input() isProject    : boolean = false;
	@Input() partsTotal   : number;
	@Input() laborTotal   : number;
	@Input() partsTotalTax: number;
	@Input() laborTotalTax: number;
	@Input() isSimpleView : boolean = false;
	@Input() isChangeOrder: boolean = false;

	readonly PaymentScheduleItemTypeEnum        = PaymentScheduleItemTypeEnum;
	readonly PaymentSchedulePercentageBasisEnum = PaymentSchedulePercentageBasisEnum;

	isPaymentScheduleShown: boolean = false;


	constructor(
		public utils: Utilities,
	) { }


	get remainingBalance(): number {
		return this.proposalTotal - this.scheduleItemsTotalAmount();
	}


	get remainingProjectBalance(): number {
		return this.projectTotal - this.scheduleItemsTotalAmount();
	}


	ngOnChanges( changes: SimpleChanges ): void {
		this.isPaymentScheduleShown = this.scheduledPayments?.length > 0;
	}


	private scheduleItemsTotalAmount( scheduleItems: IProposalPaymentScheduleItemModel[] = this.scheduledPayments ): number {
		if ( !scheduleItems?.length ) { return 0; }

		const amountArr: number[] = scheduleItems.map(( item ) => {
			// Neglecting the last item because calculated remaining balance should show in last item
			switch ( item?.itemType ) {
				default:
				case PaymentScheduleItemTypeEnum.Remaining:
					return 0;
					break;

				case PaymentScheduleItemTypeEnum.Fixed:
					return item?.amount;
					break;

				case PaymentScheduleItemTypeEnum.Percentage:
					return Number( this.getPercentAmount( item?.percent, item?.percentageBasis ) );
					break;
			}

		// Filter numbers only and neglect any other value after mapping
		}).filter( Number );

		// After getting amounts from the items, adding them together and calculating remaining balance
		return amountArr?.reduce(( a, b ) => a + b, 0 );
	}


	getAmountFromItemType( item: IProposalPaymentScheduleItemModel ): number {
		switch ( item.itemType ) {
			case PaymentScheduleItemTypeEnum.Fixed:
				return item?.amount;

			case PaymentScheduleItemTypeEnum.Percentage:
				return +this.getPercentAmount( item?.percent, item?.percentageBasis );

			case PaymentScheduleItemTypeEnum.Remaining:
				// return this.remainingBalance;
				return this.remainingProjectBalance;
		}
	}


	getPercentAmount( percentage: number, percentageBasis: PaymentSchedulePercentageBasisEnum ): number {
		let total = this.proposalTotal;

		switch ( percentageBasis ) {
			case PaymentSchedulePercentageBasisEnum.ProposalTotal:
				total = this.proposalTotal;
				break;

			case PaymentSchedulePercentageBasisEnum.LaborTotal:
				total = this.laborTotal + this.laborTotalTax;
				break;

			case PaymentSchedulePercentageBasisEnum.PartsTotal:
				total = this.partsTotal + this.partsTotalTax;
				break;
		}

		return this.utils.isValueDefined( percentage ) ? ( total * ( percentage / 100 )) : null;
	}


	getPaymentPurposeValueFromEnum( value: string ): string {
		switch ( value?.toUpperCase() ) {
			default:
			case PaymentSchedulePercentageBasisEnum.ProposalTotal:
				return 'Proposal Total';

			case PaymentSchedulePercentageBasisEnum.LaborTotal:
				return 'Labor Total';

			case PaymentSchedulePercentageBasisEnum.PartsTotal:
				return 'Parts Total';
		}
	}


	getMilestoneText( value: string, milestoneType: PaymentScheduleMilestoneTypeEnum ): string {
		if ( this.isChangeOrder && !this.isSimpleView && milestoneType === PaymentScheduleMilestoneTypeEnum.ProposalAcceptance ) {
			return 'Change Order Acceptance';
		}

		if ( this.isSimpleView && value?.toUpperCase()?.includes('PROPOSAL') ) {
			const newValue = value?.replace( 'Proposal', '' );

			return newValue;
		}

		return value;
	}
}
