import { inject } from '@angular/core';

// Utilities
import { Utilities } from '@app/shared/utilities';


export abstract class StorageService {
	protected readonly utils = inject( Utilities );

	protected storageName: string;


	// Removes all items which are expired
	protected purgeAllExpired(): void {
		try {
			const validKeys = [];
			const allKeys = this.getAllKeys();

			for ( const key of allKeys ) {
				// Store all keys except the ones used for expiry detail
				if ( key.indexOf('__expiry') === -1 ) { validKeys.push(key); }
			}

			// Loop through all keys (except the ones with expiry timestamp) - and remove them and they are expired
			for ( const key of validKeys ) {
				const item_expiry = +this.getValue(`${key}__expiry`);

				if ( item_expiry && item_expiry !== undefined ) {
					// Expiry found for key
					const NOW = Math.round(new Date().getTime() / 1000);	// In seconds
					if ( NOW >= item_expiry ) {
						// Time expired - remove values and return false
						this.removeValue(`${key}`);
						this.removeValue(`${key}__expiry`);

						continue;
					}
				}
			}
		} catch ( e ) {
			console.log('Some exception occurred in "purgeAllExpired"', e);
		}
	}


	retrieve( key: string, parse = true ): any {
		if ( !key ) {
			return;
		}

		try {
			const item = this.getValue(`${key}`);

			if ( item && item !== undefined ) {
				const item_expiry = +this.getValue(`${key}__expiry`);

				if ( item_expiry && item_expiry !== undefined ) {
					// Expiry found for key
					const NOW = Math.round(new Date().getTime() / 1000);	// In seconds
					if ( NOW >= item_expiry ) {
						// Time expired - remove values and return false
						this.removeValue(`${key}`);
						this.removeValue(`${key}__expiry`);

						return;
					}
				}

				if ( parse ) {
					return JSON.parse( item );
				}

				return item;
			}

		} catch ( e ) {
			this.utils.logError( e );
			console.log(`${this.storageName} Exception: `, e);
		}
		return;
	}


	// Expiry in seconds
	store( key: string, value: any, expiry?: number, json = true ) {
		try {
			if ( json ) {
				this.setValue( key, JSON.stringify(value) );
			} else {
				this.setValue( key, value );
			}
			if ( expiry ) {
				// Set expiry NOW + expiry value
				const NOW = Math.round( new Date().getTime() / 1000 );	// In seconds
				this.setValue( `${key}__expiry`, '' + (NOW + expiry) );	// '' + has to be used because localstorage doesn't allow anything other than in form of strings
			}
		} catch ( e ) {
			this.utils.logError( e );
			console.log(`${this.storageName} Exception: `, e);
		}
	}


	remove( key: string ) {
		try {
			this.removeValue( key );
			this.removeValue( `${key}__expiry` );
		} catch ( e ) {
			this.utils.logError( e );
			console.log(`${this.storageName} Exception: `, e);
		}
	}


	protected abstract getAllKeys(): string[];


	protected abstract setValue( key: string, value: string ): void;


	protected abstract getValue( key: string ): string;


	protected abstract removeValue( key: string ): void;

}
