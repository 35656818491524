import { Component, Input, ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { differenceInDays } from 'date-fns';

// Utility
import { Utilities } from '@app/shared/utilities';

// Models
import { IProposalPaymentModel } from '@app/core/models/proposal.models';
import { ICurrencyModel } from '@app/core/models/common.models';

// Enums
import { GatewayTypeEnum, TransactionStatusEnum } from '@app/core/enums';


@Component({
	selector       : 'app-payment-request',
	templateUrl    : './payment-request.component.html',
	styleUrls      : ['./payment-request.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentRequestComponent implements OnChanges {
	@Input() isPaymentProcessingEnabled: boolean               = false;
	@Input() payment                   : IProposalPaymentModel = null;
	@Input() currency                  : ICurrencyModel        = null;

	readonly GatewayTypeEnum       = GatewayTypeEnum;	// Needed here for Template
	readonly TransactionStatusEnum = TransactionStatusEnum;	// Needed here for Template

	isPastDue    : boolean = false;
	leftDateLabel: string  = 'Due';
	dateFormat   : string  = 'MMM dd';
	daysPastDue  : number;
	dateToShow   : Date;


	constructor(
		public utils: Utilities,
	) { }


	ngOnChanges( changes: SimpleChanges ): void {
		if ( changes?.['payment'] ) {
			this.checkPastDue();
			this.leftDateLabel = this.getLeftDateLabel();
			this.dateFormat    = this.getDateFormat( this.payment?.dueDate );
			this.dateToShow    = this.payment?.dueDate;

			switch ( this.payment.status ) {
				case TransactionStatusEnum.Verifying:
				case TransactionStatusEnum.Paid:
				case TransactionStatusEnum.Pending:

					if ( this.payment?.paymentDate ) {
						this.dateToShow = this.payment?.paymentDate;
					} else if ( this.payment?.paymentInitiatedDate ) {
						this.dateToShow = this.payment?.paymentInitiatedDate;
					}
					
					this.isPastDue = false;
					break;
			}

		}
	}


	statusPaidOrPending( payment: IProposalPaymentModel ): boolean {
		return payment?.status === TransactionStatusEnum.Paid || payment?.status === TransactionStatusEnum.Pending;
	}


	getPaidVia( payment: IProposalPaymentModel ): string {
		if ( this.statusPaidOrPending ) {
			if ( payment?.paymentProcessor === GatewayTypeEnum.Stripe ) {
				const gateway = this.utils.getPaymentGatewayText( payment?.paymentProcessor );

				return `${gateway} ${payment?.paidVia}`;
			}

			return `${payment?.paidVia}`;
		}

		return null;
	}


	private numDaysPassed( comparativeDate: Date ): number {
		if ( !comparativeDate ) { return null; }

		const days = differenceInDays( comparativeDate, new Date() );

		return days;
	}


	private checkPastDue(): void {
		const dueDate      = this.payment?.dueDate;
		const daysPassed   = this.numDaysPassed( dueDate );

		this.isPastDue = daysPassed < 0 ? true : false;

		this.daysPastDue = Math.abs( daysPassed );	// Remove -ve sign if exists
	}


	private getLeftDateLabel(): string {
		let label = 'Due';

		if ( this.payment?.status === TransactionStatusEnum.Paid ) {
			label = 'Paid';
		} else if ( this.payment?.status === TransactionStatusEnum.Pending ) {
			label = 'Pending';
		} else if ( this.payment?.status === TransactionStatusEnum.Verifying ) {
			label = 'Verifying';
		}

		return label;
	}


	private getDateFormat( date: Date ): string {
		const isSameYear = new Date().getFullYear() === new Date( date ).getFullYear();

		return isSameYear ? 'MMM dd' : 'MMM dd, yyyy';
	}

}
