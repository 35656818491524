<section class="section no-header financials-section">
	<div class="section-body no-padding f13">
		<app-loader *ngIf="loader" />
		<div class="profit-financial-summary">

			<div>
				<app-info-container *ngIf="( calculatedTotalMsrpDiscount && !clientSettings?.hideMsrpDiscounts ) || calculatedPartsDiscount" mode="green" [onlyContentProjection]="true" style="margin-top: 0">
					<div style="padding: 3px 6px">
						<div *ngIf="calculatedTotalMsrpDiscount && !clientSettings?.hideMsrpDiscounts">You received <b>{{ calculatedTotalMsrpDiscount | currencyFormat:curFormat:curSymbol }}</b> in line item discounts<ng-container *ngIf="!viewSimpleProposal"> on this proposal</ng-container>.</div>
						<div *ngIf="calculatedPartsDiscount as _partsDiscount">You received an additional <b>{{ _partsDiscount | currencyFormat:curFormat:curSymbol }}</b> in discounts on parts.</div>
					</div>
				</app-info-container>
				<ng-container *ngIf="calculatedRecurringServiceTotal > 0">
					<app-info-container mode="light-gray" [onlyContentProjection]="true" style="margin-top: 0">
						<div style="padding: 3px 6px">
							<div class="row">
								<div class="col-8">
									<app-subtle-label class="bigger bold gray">RECURRING SERVICES</app-subtle-label>
								</div>
								<div class="col-4 text-end">
									<app-subtle-label class="bigger bold gray">{{ calculatedRecurringServiceTotal | currencyFormat:curFormat:curSymbol }}</app-subtle-label>
								</div>
							</div>
							<app-br class="grid-fixed" />
							<div class="row">
								<div class="col-12">
									<div *ngFor="let item of recurringServiceItems" class="recurring-item">{{ item?.name }} - {{ item?.total | currencyFormat:curFormat:curSymbol }}<ng-container *ngIf="item?.quantity !== 1">&nbsp;&nbsp;x{{ item?.quantity }}</ng-container></div>
								</div>
							</div>
						</div>
					</app-info-container>
				</ng-container>
			</div>

			<div>
				<div class="pricing-item" *ngIf="!clientSettings?.hideLaborTotal && calculatedPartsDiscount">
					<div>Parts</div>
					<div class="text-end">{{ calculatedPartsWithoutDiscount | currencyFormat:curFormat:curSymbol }}</div>
				</div>

				<div class="pricing-item" *ngIf="calculatedPartsDiscount as _partsDiscount">
					<div>Additional Parts Discount&nbsp;<span *ngIf="financials?.discountType === DiscountTypeEnum.Percentage" class="f12">({{ financials?.discountPercent | number: '1.0-3' }}%)</span></div>
					<div class="text-end"><ng-container *ngIf="_partsDiscount !== 0">({{ _partsDiscount | currencyFormat:curFormat:curSymbol }})</ng-container></div>
				</div>

				<div class="pricing-item" *ngIf="!clientSettings?.hideLaborTotal">
					<div>Parts Total</div>
					<div class="text-end">{{ calculatedPartsTotal | currencyFormat:curFormat:curSymbol }}</div>
				</div>

				<div class="pricing-item" *ngIf="!clientSettings?.hideLaborTotal">
					<div>Labor Total</div>
					<div class="text-end">{{ calculatedLaborTotal | currencyFormat:curFormat:curSymbol }}</div>
				</div>

				<div class="pricing-item">
					<div><b>Subtotal</b></div>
					<div class="text-end"><b>{{ calculatedLaborTotal + calculatedPartsTotal | currencyFormat:curFormat:curSymbol }}</b></div>
				</div>

				<div class="pricing-item">
					<div>
						<span>
							Sales Tax
							@if ( !clientSettings?.hideLaborTotal ) {
								<br />
								<span class="fw-normal color-text f12">
									@if ( showPartTax ) {
										<ng-container *ngTemplateOutlet="taxInfoTmpl; context: {
											rate1    : financials?.taxDetails?.taxInformation?.partTaxInfo1?.taxRate,
											name1    : financials?.taxDetails?.taxInformation?.partTaxInfo1?.taxName,
											rate2    : financials?.taxDetails?.taxInformation?.partTaxInfo2?.taxRate,
											name2    : financials?.taxDetails?.taxInformation?.partTaxInfo2?.taxName,
											applyTo  : 'Parts'
										}"></ng-container>
									}
									<br *ngIf="showPartTax && showLaborTax" />
									@if ( showLaborTax ) {
										<ng-container *ngTemplateOutlet="taxInfoTmpl; context: {
											rate1    : financials?.taxDetails?.taxInformation?.laborTaxInfo1?.taxRate,
											name1    : financials?.taxDetails?.taxInformation?.laborTaxInfo1?.taxName,
											rate2    : financials?.taxDetails?.taxInformation?.laborTaxInfo2?.taxRate,
											name2    : financials?.taxDetails?.taxInformation?.laborTaxInfo2?.taxName,
											applyTo  : 'Labor'
										}"></ng-container>
									}
								</span>
							}
						</span>
					</div>

					<div class="text-end">
						<span>{{ calculatedTaxAmount | currencyFormat:curFormat:curSymbol }}</span>
					</div>
				</div>

			</div>

		</div>

	</div>

	<footer style="padding: 0">
		<div class="profit-financial-summary" style="min-height: 65px;">
			<!-- Don't remove -->
			<div class="d-none d-md-block"></div>

			<div>
				<div class="pricing-item" style="margin-bottom: 0">
					<h3 class="footer-heading">
						{{ viewSimpleProposal ? 'Grand Total' : ( isHighlightsShown ? 'Total With Changes' : 'Proposal Total' ) }}
					</h3>
					<div class="text-end"><h3 class="footer-heading">{{ calculatedGrandTotal | currencyFormat:curFormat:curSymbol }}</h3></div>
				</div>
				<div class="recurring-label" *ngIf="recurringServiceItems?.length">Recurring services not included</div>
			</div>
		</div>
	</footer>
</section>


<ng-template #taxInfoTmpl
	let-rate1="rate1"
	let-name1="name1"
	let-rate2="rate2"
	let-name2="name2"
	let-applyTo="applyTo"
>
	<span>
		<ng-container *ngIf="applyTo">{{ applyTo }}:&nbsp;</ng-container>
		<ng-container *ngIf="rate1 !== null && rate1 !== undefined && rate1 !== ''">{{ rate1 | number: '1.0-3' }}%<ng-container *ngIf="name1">&nbsp;{{ name1 }}</ng-container></ng-container>
		<ng-container *ngIf="rate2 !== null && rate2 !== undefined && rate2 !== ''">, {{ rate2 | number: '1.0-3' }}%<ng-container *ngIf="name2">&nbsp;{{ name2 }}</ng-container></ng-container>
	</span>
</ng-template>
