import { Component, Input, ChangeDetectionStrategy, OnChanges, SimpleChanges, inject, OnInit, ChangeDetectorRef } from '@angular/core';

// Utility
import { Utilities } from '@app/shared/utilities';
import { ImageUtils } from '@app/shared/image-utils';

// Services
import { ChangeOrderService } from '@app/proposals/services/change-order.service';

// Models
import {
	IProposalAreaPartModel,
	IProposalAreaLaborModel,
	IProposalAreaCustomItemModel,
	IProposalClientSettingsModel,
	IProposalAreaItemModel,
} from '@app/core/models/proposal.models';
import { ICurrencyModel } from '@app/core/models/common.models';

// Enums
import { AreaItemTypeEnum, ChangeOrderItemActionEnum, CloudinaryPathEnum, ProposalStatusEnum } from '@app/core/enums';


@Component({
	selector       : 'app-proposal-line-item',
	templateUrl    : './proposal-line-item.component.html',
	styleUrls      : ['./proposal-line-item.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProposalLineItemComponent implements OnInit, OnChanges {
	private readonly cd                = inject( ChangeDetectorRef );
	public readonly changeOrderService = inject( ChangeOrderService );
	public readonly utils              = inject( Utilities );

	@Input() item             : IProposalAreaItemModel = null;
	@Input() isOriginalItem   : boolean                = false;
	@Input() isChangeOrderItem: boolean                = false;
	@Input() areaId           : number                 = null;
	@Input() areaOptionId     : number                 = null;
	@Input() nested           : boolean                = false; // Tells if item is nested itself
	@Input() isCombinedParent : boolean                = false;
	@Input() currency         : ICurrencyModel         = null;
	@Input() clientSettings   : IProposalClientSettingsModel;
	@Input() changeOrderNumber: number;


	readonly AreaItemTypeEnum            = AreaItemTypeEnum;	// Needed here for Template
	readonly CloudinaryPathEnum          = CloudinaryPathEnum;
	readonly ChangeOrderItemActionEnum   = ChangeOrderItemActionEnum;
	readonly ProposalStatusEnum          = ProposalStatusEnum;
	readonly getProductThumbnailImageUrl = ImageUtils.getProductThumbnailImageUrl;

	part         : IProposalAreaPartModel;
	labor        : IProposalAreaLaborModel;
	customItem   : IProposalAreaCustomItemModel;
	type         : AreaItemTypeEnum;
	isPlaceholder: boolean = false;

	msrp        : number;
	msrpDiscount: number;

	recurringService: boolean;

	readonly changeOrderItemTokens: {
		isOriginalItem: boolean;
		item          : IProposalAreaItemModel;
		part?         : IProposalAreaPartModel;
		labor?        : IProposalAreaLaborModel;
		customItem?   : IProposalAreaCustomItemModel;
	};


	ngOnInit(): void {
		this.changeOrderService.changeTrigger$.subscribe( () => {
			this.markForChange();
		});
	}


	ngOnChanges( changes: SimpleChanges ): void {
		if ( changes?.['item'] ) {
			this.type = this.item.type;

			switch ( this.type ) {
				case AreaItemTypeEnum.Part:
					this.part          = this.item as IProposalAreaPartModel;
					this.msrp          = this.part?.msrp;
					this.msrpDiscount  = this.part?.msrpDiscount;
					this.isPlaceholder = this.part?.isPlaceholder;
					break;

				case AreaItemTypeEnum.Labor:
					this.labor            = this.item as IProposalAreaLaborModel;
					this.recurringService = !!this.labor?.isRecurringService;
					this.isPlaceholder    = this.labor?.isPlaceholder;
					break;

				case AreaItemTypeEnum.Custom:
					this.customItem = this.item as IProposalAreaCustomItemModel;
					this.recurringService = !!this.customItem?.isRecurringService;
					break;
			}
		}
	}


	get isChangeOrder(): boolean {
		return this.changeOrderService.isChangeOrder;
	}


	private markForChange(): void {
		this.cd.markForCheck();
	}

}
