<div [ngClass]="actionClasses">
	<div>
		<ng-container *ngIf="data.mode !== 'default'">
			<i *ngIf="data.mode === 'success' || data.mode === 'error'" class="fad f18 color-orange pull-down-hard-2" [class.fa-check-circle]="data.mode === 'success'" [class.fa-times-circle]="data?.mode === 'error'"></i>

			<span *ngIf="data.mode === 'loading'" class="spinner"><span></span><span></span><span></span><span></span></span>
			&nbsp;&nbsp;
		</ng-container>

		<ng-container *ngIf="!isMessageTemplate">{{ data?.message }}</ng-container>
		<ng-container *ngIf="isMessageTemplate">
			<ng-container [ngTemplateOutlet]="messageTemplate"></ng-container>
		</ng-container>

	</div>

	<ng-container *ngIf="data?.actionButton">
		<button *ngIf="!isButtonTemplate" (click)="matSnackBarRef?.dismissWithAction()" class="_button _small _blank-primary _dark-hover uppercase f12 fw-semibold" style="margin: -8px; letter-spacing: 1.7px">{{ data?.actionButton }}</button>
		<ng-container *ngIf="isButtonTemplate">
			<ng-container [ngTemplateOutlet]="buttonTemplate"></ng-container>
		</ng-container>
	</ng-container>

</div>
