<div bsModal #modal="bs-modal" (onHidden)="hideModal()" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down black-modal header-green footer-stick">
		<div class="modal-content">
			<div class="modal-header justify-content-center">
				<button type="button" class="close" aria-label="Close" (click)="modal.hide()"><span aria-hidden="true">&times;</span></button>
				<h5 class="modal-title">Options Must Be Chosen</h5>
			</div>
			<div class="modal-body">
				<div class="text-center" style="max-width: 400px; margin: auto">
					<p>Before making a decision, please return<ng-container *ngIf="!isSimpleView"> to the proposal</ng-container> and choose your preferred option for the following areas:</p>

					<ul class="area-list">
						<li *ngFor="let area of unSelectedAreas">{{ area?.name | truncate: 40 }}</li>
					</ul>

					<app-br class="big-grid-fixed" />

					<button (click)="okay()" class="_button _green-outline _fixed-medium">Ok</button>

				</div>
			</div>
		</div>
	</div>
</div>
