// Bugsnag needs to be imported first
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { bugsnagClient } from './bugsnag-client';

// create a factory which will return the bugsnag error handler
export function errorHandlerFactory() {
	return bugsnagClient ? new BugsnagErrorHandler( bugsnagClient ) : null;
}

import { NgModule, ErrorHandler, Injectable } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { DefaultUrlSerializer, UrlTree, UrlSerializer } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// 3rd party
import { Angulartics2Module } from 'angulartics2';

// Environment
import { environment } from '@env/environment';

// Make URLs case insensitive
@Injectable()
export class LowerCaseUrlSerializer extends DefaultUrlSerializer {

	override parse( url: string ): UrlTree {
		// Split only on first occurrence of `?` character and lowercase the first part of the URL. Don't lowercase the query string.
		const index      = url?.indexOf('?');
		const firstPart  = index !== -1 ? url?.slice( 0, index ) : url;
		const secondPart = index !== -1 ? url?.slice( index + 1 ) : '';
		const joinAgain  = [ firstPart?.toLowerCase(), secondPart ].filter( Boolean ).join( '?' );
		return super.parse( joinAgain );
	}
}

const MODULES = [
	BrowserAnimationsModule,
];


@NgModule({
	imports: [
		...MODULES,
		Angulartics2Module.forRoot(),
	],
	exports: [
		...MODULES,
		Angulartics2Module,
	],
	providers: [
		provideHttpClient(),
		Title,
		{
			provide: UrlSerializer,
			useClass: LowerCaseUrlSerializer,
		},
		environment.name !== 'local' ? { provide: ErrorHandler, useFactory: errorHandlerFactory } : [],
	],
})
export class CoreModule {}
