import 'core-js/features/global-this';	// Needed for IMask library with older browsers
import IMask, { FactoryArg } from 'imask'; // NOTE: Importing from `'imask/esm/imask'` causes issues with masks with blocks as `mask` exports out as undefined


export const CurrencyTextMask/* : ( format?: string ) => IMask.MaskedNumberOptions*/ = ( format: string = 'LN333,.2F' ) => {
	const groupingOne         = format[2];
	const groupingTwo         = format[3]; // Not implemented as yet
	const groupingThree       = format[4]; // Not implemented as yet
	const groupingSymbol      = format[5]?.replace('S', ' ')?.replace('N', '');
	const decimalSymbol       = format[6]?.replace('S', ' ')?.replace('N', '');
	const numberDecimalPlaces = format[7];
	const decimalForced       = format[8];

	return {
		mask              : Number,
		thousandsSeparator: groupingSymbol,
		scale             : Number( numberDecimalPlaces ), // digits after decimal
		signed            : true, // allow negative
		normalizeZeros    : true, // appends or removes zeros at ends
		radix             : decimalSymbol, // fractional delimiter
		padFractionalZeros: decimalForced?.toUpperCase() === 'F', // if true, then pads zeros at end to the length of scale
	};
};


export const MonthDayYearMask: FactoryArg = {	// type IMask.AnyMaskedOptions has issues with blocks
	mask     : 'MM / DD / YYYY',
	overwrite: true,		// Enables characters overwriting instead of inserting
	lazy     : true,		// False will make placeholder show visible
	blocks   : {
		DD: { mask: IMask.MaskedRange, placeholderChar: 'D', from: 1, to: 31, maxLength: 2 },
		MM: { mask: IMask.MaskedRange, placeholderChar: 'M', from: 1, to: 12, maxLength: 2 },
		YYYY: { mask: IMask.MaskedRange, placeholderChar: 'Y', from: 1800, to: 9999, maxLength: 4 },
	},
};
