import { Component, ChangeDetectionStrategy } from '@angular/core';


@Component({
	selector       : 'app-subtle-label',
	templateUrl    : './subtle-label.component.html',
	styleUrls      : ['./subtle-label.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubtleLabelComponent {

}
