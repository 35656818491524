import { Injector } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { retryWhen, catchError, timeout, delay, scan } from 'rxjs/operators';


// Models
import { ErrorModel } from '@app/core/models/error.model';

import { environment } from '@env/environment';

/*
	NOTES:::
	retry logic copied from: https://stackoverflow.com/questions/39480348/angular-2-rxjs-observable-retry-except-on-429-status/39928110#39928110
*/

export abstract class BaseApiService {

	readonly numberOfTries: number 						 = 4; 			// Number of tries
	readonly delayInRetry : number 						 = 500; 		// Number of milliseconds between each retry
	readonly getTimeout   : number 						 = 45000;		// Total number of milliseconds to throw timeout error (for GET requests)
	readonly postTimeout  : number 						 = 45000;		// Total number of milliseconds to throw timeout error (for POST requests)
	private readonly skipRetryOnStatuses: Array<number>  = [500, 402, 403];

	protected http      : HttpClient;
	protected baseApiUrl: string = `${environment.apiUrl}`;


	constructor(
		protected injector: Injector,
	) {
		// Load dependencies using Injector so that we can decouple BaseApiService extending services
		this.http = this.injector.get( HttpClient );
	}


	// Get data from server
	httpGet( URL: string, requestOptions = this.getRequestOptions() ): Observable<any> {
		try {

			return this.http.get( URL, requestOptions )
			.pipe(
				retryWhen( this.retryStrategy() ),
				timeout( this.getTimeout ),
				catchError( this.handleError.bind( this ) )
			);

		} catch ( e ) {
			return this.handleError( e );
		}
	}


	// Send data to server
	httpPost( URL: string, data: any, requestOptions = this.getRequestOptions(), errorHandling: boolean = true ): Observable<any> {
		try {

			if ( errorHandling ) {
				return this.http.post(URL, data, requestOptions)
				.pipe(
					// map( this.extractData ),
					catchError( this.handleError )
				);
			} else {
				return this.http.post(URL, data, requestOptions);
				// .pipe(map( this.extractData ));
			}

		} catch ( e ) {
			return this.handleError( e );
		}
	}


	httpDelete( URL: string, requestOptions = this.getRequestOptions(), errorHandling: boolean = true ): Observable<any> {
		try {

			if ( errorHandling ) {
				return this.http.delete(URL, requestOptions)
				.pipe(
					// map( this.extractData ),
					catchError( this.handleError )
				);
			} else {
				return this.http.delete(URL, requestOptions);
				// .pipe(map( this.extractData ));
			}

		} catch ( e ) {
			return this.handleError( e );
		}
	}


	// Can be overridden by caller - for example: retryWhen( this.retryStrategy( { attempts: 5, retryDelay: 1500 } ) )
	private retryStrategy( { attemps = this.numberOfTries, retryDelay = this.delayInRetry }: {attemps?: number, retryDelay?: number} = {} ): (e: Observable<HttpErrorResponse>) => Observable<any> {
		return ( errors: Observable<HttpErrorResponse> ) => {
			return errors
			.pipe(
				scan( ( tried: number, error: HttpErrorResponse ) => {
					if ( this.skipRetryOnStatuses.indexOf( error.status ) > -1 ) {
						// Need to throw error here so its catched up in chain - cannot return
						throw error;
					}

					tried++;
					if ( tried < attemps ) {
						return tried;
					} else {
						// Need to throw error here so its catched up in chain - cannot return
						throw error;
					}
				}, 0),
				delay( retryDelay ),
				// timeout( this.getTimeout )
			);
		};
	}


	// Usage:
	/*
		let requestHeaders = this.getRequestOptions( new Headers({'My-new-header': 'its-value'}) );

		responseType: 'arraybuffer' | 'blob' | 'json' | 'text'
	*/
	protected getRequestOptions( additionalHeaders?: HttpHeaders, responseType?: 'arraybuffer' | 'blob' | 'json' | 'text' ) {
		try {
			const options = {};
			let headers   = new HttpHeaders();	// HttpHeaders is immutable - every set(), append(), delete() returns a new clone
			headers = headers.append('Accept', 'application/json');
			headers = headers.append('Content-Type', 'application/json');

			// Any additional headers
			if ( additionalHeaders?.keys()?.length ) {
				additionalHeaders.keys().forEach( (headerKey: string) => {
					if ( headerKey.toLowerCase() === 'accept' || headerKey.toLowerCase() === 'content-type' ) {
						// If headerKey is 'Accept' or 'Content-Type' delete previous one and use new one
						headers = headers.delete( headerKey );
					}
					headers = headers.append( headerKey, additionalHeaders.get( headerKey ) );
				});
			}

			if ( responseType ) {
				options['responseType'] = responseType;
			}

			options['headers'] = headers;

			return options;

		} catch ( e ) {
			console.log(`Exception`, e);
			return this.handleError( e );
		}
	}


	protected extractData( res: HttpResponse<any> ) {
		const body  = res.body;
		if ( body ) {
			return (body.data != null) ? body.data : body;
		}

		return null;
	}


	protected handleError( error: any ): Observable<ErrorModel> {
		let errMsg   = ( error.message ) ? error.message : (error.status ? `${error.status} - ${error.statusText}` : 'Server error');
		let errCode  = ( error.status ) ? error.status  : null;

		// Sometimes handleError gets ErrorModel already so have to capture data from there
		if ( error.code ) {
			errCode = error.code;
		}

		try {
			// If error is a valid JSON - pull out the responseStatus message (sent from server)
			const errorObj = error.error; // .json();	// Sometimes exists in response and sometime doesn't
			if ( errorObj && errorObj.responseStatus ) {
				if ( errorObj.responseStatus.message ) {
					errMsg = errorObj.responseStatus.message;
				}

				if ( errorObj.responseStatus.errorCode ) {
					errCode = errorObj.responseStatus.errorCode;
				}
			}
		} catch ( e ) {
			// Cannot throw error here otherwise its shape changes
			// error.json was invalid
		}

		return observableThrowError( new ErrorModel( errCode, errMsg ) );
	}


	// Generates string i.e: param1=paramValue1&param2=paramValue2 from {param1: paramValue1, param2: paramValue2 }
	protected generateQueryString( paramsObject ): string {
		const str = Object.keys(paramsObject).map((key) => {
			return key + '=' + encodeURIComponent(paramsObject[key]);
		}).join('&');
		return str;
	}


	protected replaceUrlProperty( url: string, propertyName: string, value: number | string ): string {
		const regex = new RegExp(propertyName, 'g');

		return url?.replace( regex, value as string );
	}

}
