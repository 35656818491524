import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy, inject } from '@angular/core';

// Directives
import { ModalDirective } from 'ngx-bootstrap/modal';

// Utilities
import { Utilities } from '@app/shared/utilities';

// Models
import { IProposalAreaModel } from '@app/core/models/proposal.models';


@Component({
	selector       : 'app-pending-options-modal',
	templateUrl    : './modal-pending-options.component.html',
	styleUrls      : ['./modal-pending-options.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingOptionsModalComponent implements OnInit {
	public readonly utils = inject( Utilities );

	@ViewChild('modal', { static: true }) modal: ModalDirective;

	@Input() unSelectedAreas : IProposalAreaModel[] = [];
	@Input() isSimpleView    : boolean = false;

	@Output() eventCloseModal = new EventEmitter<void>();
	@Output() eventModalRef   = new EventEmitter<ModalDirective>();
	@Output() eventOkayButton = new EventEmitter<void>();


	ngOnInit() {
		// Emit a modal ref if parent component wants to use its properties
		this.eventModalRef.emit( this.modal );

		// Show modal once it has stabilized little
		setTimeout( () => this.modal.show(), 10 );
	}


	okay(): void {
		this.hideModal();
		this.eventOkayButton.emit();
	}


	hideModal(): void {
		this.eventCloseModal.emit();
		this.modal.hide();
	}

}
