import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';

// Shared Pipes
import { PhoneFormatPipe, TruncateCharactersPipe, TruncateWordsPipe, TrackByPropertyPipe, CapitalizePipe, CurrencyFormatPipe, PipeFunction } from '@shared/pipes';

// Components
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { LoaderComponent } from './loader/loader.component';
import { BrComponent } from '@app/shared/components/br/br.component';
import { AlertContainerComponent } from './alert-container/alert-container.component';
import { SubtleLabelComponent } from '@app/shared/components/subtle-label/subtle-label.component';
import { InfoContainerComponent } from '@app/shared/components/info-container/info-container.component';
import { SnackbarContainerComponent } from '@app/shared/components/snackbar-container/snackbar-container.component';

// Directives
import { CloudinaryBackgroundImageDirective } from '@app/shared/directives/cloudinary-background-image.directive';
import { TypedTemplateDirective } from '@app/shared/directives/typed-template.directive';


// 3rd party
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { ResponsiveModule, IResponsiveConfig } from '@viablelogic/ngx-responsive';
import { IMaskModule } from 'angular-imask';


// Environment
import { environment } from '@env/environment';


// Reduced debounceTime for NG Responsive package
const ResponsiveSettings: IResponsiveConfig = {
	breakPoints: {
		xs: { max: 575 },
		sm: { min: 576, max: 767 },
		md: { min: 768, max: 991 },
		lg: { min: 992, max: 1199 },
		xl: { min: 1200, max: 1399 },
		xxl: { min: 1400 },
	},
	debounceTime: 1, // allow to debounce checking timer
};


const MODULES = [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	RouterModule,
	IMaskModule,
	MatSnackBarModule,
];

const SHARED_PIPES = [
	PhoneFormatPipe,
	TruncateCharactersPipe,
	TruncateWordsPipe,
	TrackByPropertyPipe,
	CapitalizePipe,
	CurrencyFormatPipe,
	PipeFunction,
];

const PIPES = [];

const COMPONENTS = [
	PageNotFoundComponent,
	LoaderComponent,
	BrComponent,
	AlertContainerComponent,
	SubtleLabelComponent,
	InfoContainerComponent,
	SnackbarContainerComponent,
];

const DIRECTIVES = [
	CloudinaryBackgroundImageDirective,
	TypedTemplateDirective,
];

const STANDALONE = [
	...SHARED_PIPES,
];


@NgModule({
	imports: [
		...MODULES,
		...STANDALONE,
		ResponsiveModule.forRoot( ResponsiveSettings ),
		BsDropdownModule.forRoot(),
		ModalModule.forRoot(),
		AlertModule.forRoot(),
		TabsModule.forRoot(),
		ButtonsModule.forRoot(),
	],
	declarations: [
		...PIPES,
		...COMPONENTS,
		...DIRECTIVES,
	],
	exports: [
		...MODULES,
		...PIPES,
		...COMPONENTS,
		...DIRECTIVES,
		...STANDALONE,
		ResponsiveModule,
		BsDropdownModule,
		ModalModule,
		AlertModule,
		TabsModule,
		ButtonsModule,
	],
	providers: [],
})
export class SharedModule {}
