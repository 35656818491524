import { NgModule }	from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { PageNotFoundComponent } from '@app/shared/page-not-found/page-not-found.component';
import { ProposalDetailComponent } from './proposals/proposal-detail/proposal-detail.component';

export const routes: Routes = [
	{
		path: ':id/:token',
		component: ProposalDetailComponent,
	},
	{
		path: ':id/:token/:clientemail',		// TODO: REMOVE IN FUTURE
		component: ProposalDetailComponent,
	},
	{ path: '**', component: PageNotFoundComponent },					// When URL is not found - show PageNotFoundComponent
];


@NgModule({
	imports: [ RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }) ],	// Lazy loaded routes are also preloaded after the boot files are done
	exports: [ RouterModule ],
})
export class AppRoutingModule {}
