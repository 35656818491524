<div bsModal #modal="bs-modal" (onHidden)="hideModal()" [config]="{backdrop: 'static'}" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered modal-fullscreen-md-down black-modal modal-lg footer-stick height-standard">
		<div class="modal-content">
			<div class="modal-header justify-content-center">
				<button type="button" class="close" aria-label="Close" (click)="modal.hide()"><span aria-hidden="true">&times;</span></button>
				<h5 class="modal-title">&nbsp;</h5>
			</div>
			<div class="modal-body">
				<app-loader *ngIf="loader" [circle]="true" class="position-fixed"></app-loader>
				<app-alert-container [containerName]="alertContainer"></app-alert-container>

				<app-br class="big-grid-fixed" />

				<div class="central-container text-center">
					<div class="row">
						<div class="col-12">
							<h2 class="std color-black">Great!<br />Let's make it official.</h2>

							<app-br class="grid-fixed" />

							<p style="max-width: 340px; margin-left: auto; margin-right: auto;">Please sign here to accept the project design, terms, and payment schedule.</p>
						</div>
					</div>

					<app-br class="bigger-grid-fixed" />

					<div class="row">
						<div class="col-12 col-lg-7">
							<div class="form-field">
								<label>Your Full Name</label>
								<input [(ngModel)]="signData.clientName" class="bottom-spacer" type="text" placeholder="" />
							</div>
						</div>
						<div class="col-12 col-lg-5">
							<div class="form-field">
								<label>Date (mm / dd / yyyy)</label>
								<input type="text" class="bottom-spacer"
									[(ngModel)] = "dateField"
									[imask]     = "dateMask"
									[unmask]    = "true"
								/>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-12">
							<div class="form-field sign-field-container">
								<label>Signature</label>
								<div class="sign-pad">
									<span class="sign-place">X</span>
									<input (click)="clearSign()" type="button" class="sign-clear-btn" value="Clear" />

									<signature-pad class="d-block" [options]="signaturePadOptions" #signaturePad (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>

								</div>
							</div>
						</div>
					</div>

					<app-br class="big-grid-fixed" />

					<div class="btn-container">
						<button (click)="submitAccept()" class="_button _big _green fw-bold f15" [class.disabled]="!isFormValid()">Submit and Accept Proposal</button>
					</div>
				</div>

				<app-br class="bigger-grid-fixed" />

			</div>
		</div>
	</div>
</div>
