<div class="cover-page">
    <div class="proposal-meta d-flex align-items-center justify-content-between">
        <div class="column-1">
            <div *ngIf="logoImageAssetId" class="logo-container">
                <img class="img-fluid" [src]="[ CloudinaryPathEnum.Company + '/' + logoImageAssetId + '.png' ] | pipeFunction: getCompanyLogoImageUrl" />
            </div>
            <div *ngIf="!logoImageAssetId" class="color-charcoal">
                <h5 class="std">{{ dealer?.companyName | truncate: 22 }}</h5>
            </div>
        </div>
        <div class="column-2">
            <app-subtle-label *ngIf="expiry" class="gray uppercase narrow big">
                Expires

                <ng-container *ngIf="proposalStatus !== ProposalStatusEnum.Draft"><ng-container *ngTemplateOutlet="dateTmpl"></ng-container></ng-container>

                <ng-container *ngIf="proposalStatus === ProposalStatusEnum.Draft">
                    <ng-container *ngIf="expiry?.days; else showDate">
                        {{ expiry?.days }} Days After Submission
                    </ng-container>

                    <ng-template #showDate>
                        <ng-container *ngTemplateOutlet="dateTmpl"></ng-container>
                    </ng-template>
                </ng-container>

                <ng-template #dateTmpl>
                    {{ expiry?.date | date:'MMMM dd, yyyy' }}
                </ng-template>
            </app-subtle-label>
        </div>
    </div>

    <app-br class="big-grid-fixed" />
    <app-br class="grid-fixed" />

    <h2 class="std proposal-name color-charcoal">{{ proposalName }}</h2>

    <app-br class="bigger-grid-fixed" />

    <section class="proposal-info color-black">
        <app-subtle-label *ngIf="hasClientData" class="gray uppercase narrow big">Customer</app-subtle-label>
        <app-br class="grid-fixed" />
        <app-br class="tiny-grid-fixed" />

        <div class="client-dealer-info">
            <div class="row negative">
                <div class="col-md-6">
                    <h6 *ngIf="client?.displayName" class="std client-name">{{ client?.displayName }}</h6>
                    <div *ngIf="client?.email">{{ client?.email }}</div>
                    <div>
                        <ng-container *ngIf="client?.street">{{ client?.street }}</ng-container>
                        <ng-container *ngIf="client?.city"> {{ client?.city }}</ng-container>
                        <ng-container *ngIf="client?.stateAbbreviation"> {{ client?.stateAbbreviation }}</ng-container>
                        <ng-container *ngIf="client?.postalCode"> {{ client?.postalCode }}</ng-container>
                    </div>
                </div>
                <div *ngIf="!hideCompanyAddress" class="col-md-6 text-md-end mt-3 mt-md-0">
                    <h6 class="std company-name">{{ dealer?.companyName }}</h6>
                    <div *ngIf="dealer?.street || dealer?.suite">{{ dealer?.street }} {{ dealer?.suite }}</div>
                    <div *ngIf="dealer?.city || dealer?.stateAbbreviation || dealer?.postalCode">{{ dealer?.city }}<ng-container *ngIf="dealer?.stateAbbreviation || dealer?.postalCode">, {{ dealer?.stateAbbreviation }} {{ dealer?.postalCode }}</ng-container></div>
                </div>
            </div>
        </div>

        <app-br class="big-grid-fixed" />
    </section>
</div>
