@if ( item && isValidType ) {
	<div class="item {{ showChangeOrderData && 'change-order-item change-order-number-' + changeOrderNumber }} change-order-{{ changeOrderNumber }}-background-color" [class.nested-item]="nested">

		@if ( showChangeOrderData ) {
			@if ( item?.changeOrderActionType === ChangeOrderItemActionEnum.MiscChanges || item?.changeOrderActionType === ChangeOrderItemActionEnum.Added ) {
				@if ( showMiscLabel ) {
					<ng-container *ngTemplateOutlet="changeOrderChangeLabelTmpl" />
				}

				<ng-container *ngTemplateOutlet="itemTmpl; context: { item: item, isChangeOrderItem: true }" />
			} @else {
				<ng-container *ngTemplateOutlet="changeOrderChangeLabelTmpl" />

				<ng-container *ngTemplateOutlet="itemTmpl; context: { item: item, isOriginalItem: true }" />

				@for ( changeOrderItem of changeOrderItems; track item?.id ) {
					<ng-container *ngTemplateOutlet="itemTmpl; context: { item: changeOrderItem, isChangeOrderItem: true }" />
				}
			}
		} @else {
			<ng-container *ngTemplateOutlet="itemTmpl; context: { item: item }" />
		}

	</div>
}


<ng-template #itemTmpl [typedTemplate]="lineItemTokens" let-item="item" let-isOriginalItem="isOriginalItem" let-isChangeOrderItem="isChangeOrderItem">
	<app-proposal-line-item
		[item]              = "item"
		[isOriginalItem]    = "isOriginalItem"
		[isChangeOrderItem] = "isChangeOrderItem"
		[areaId]            = "areaId"
		[areaOptionId]      = "areaOptionId"
		[nested]            = "nested"
		[isCombinedParent]  = "isCombinedParent"
		[currency]          = "currency"
		[changeOrderNumber] = "changeOrderNumber"
		[clientSettings]    = "clientSettings"
	/>
</ng-template>

<ng-template #changeOrderChangeLabelTmpl>
	@if ( ( triggerValue | pipeFunction : consolidateChangeOrderTags : this ); as changeOrderTags ) {
		@for ( changeOrderTag of changeOrderTags; track changeOrderTag?.id ) {
			<div class="change-order-labels d-flex justify-content-between align-items-center fw-semibold">
				<div class="action-label f10">
					<div class="action-icon-container">
						@switch ( changeOrderTag?.actions?.[0] ) {
							@case ( ChangeOrderItemActionEnum.Added ) { <i class="fa-solid fa-plus action-icon"></i> }
							@case ( ChangeOrderItemActionEnum.Refunded ) { <i class="fa-solid fa-minus action-icon"></i> }
							@case ( ChangeOrderItemActionEnum.Replaced ) { <i class="fa-solid fa-arrow-right-arrow-left action-icon replaced-icon"></i> }
							@case ( ChangeOrderItemActionEnum.PriceIncreased ) { <ng-container *ngTemplateOutlet="priceChangedIconTmpl" /> }
							@case ( ChangeOrderItemActionEnum.PriceDecreased ) { <ng-container *ngTemplateOutlet="priceChangedIconTmpl" /> }
							@case ( ChangeOrderItemActionEnum.QtyIncreased ) { <ng-container *ngTemplateOutlet="qtyChangedIconTmpl" /> }
							@case ( ChangeOrderItemActionEnum.QtyDecreased ) { <ng-container *ngTemplateOutlet="qtyChangedIconTmpl" /> }
							@case ( ChangeOrderItemActionEnum.Changed ) { <ng-container *ngTemplateOutlet="qtyChangedIconTmpl" /> }
							@case ( ChangeOrderItemActionEnum.MiscChanges ) { <ng-container *ngTemplateOutlet="qtyChangedIconTmpl" /> }
						}

						<ng-template #priceChangedIconTmpl>
							<i class="fa-solid fa-dollar action-icon price-icon"></i>
						</ng-template>
						<ng-template #qtyChangedIconTmpl>
							<i class="fa-solid fa-hashtag action-icon number-icon"></i>
						</ng-template>
					</div>
					{{ changeOrderTag?.actions?.length | i18nPlural:
						{
							'=1': ( changeOrderTag?.actions?.[0] | pipeFunction : utils.getChangeOrderItemActionType ),
							'=2': ( changeOrderTag?.actions?.[0] | pipeFunction : utils.getChangeOrderItemActionType ) + ' and ' + ( changeOrderTag?.actions?.[1] | pipeFunction : utils.getChangeOrderItemActionType ),
							'other': 'Multiple Changes'
						}
					}}
				</div>
				@if ( changeOrderService.getChangeOrderInfo( changeOrderTag?.id ); as changeOrderInfo ) {
					<a class="date-label f8" [attr.href]="changeOrderInfo?.previewUrl" target="_blank">
						@if ( changeOrderInfo.status === ProposalStatusEnum.Accepted ) {
							Accepted {{ changeOrderInfo?.acceptedDate | date:'MM/dd/yyyy' }}
						} @else { Pending }
						On Change Order {{ changeOrderInfo?.number }} <i class="fa-solid fa-chevron-right chevron-icon"></i>
					</a>
				}
			</div>
		}
	}
</ng-template>

<ng-content></ng-content>
