import { Component, AfterViewInit, HostListener, OnInit, OnDestroy } from '@angular/core';
import { Renderer2 } from '@angular/core';		// To Use View Operations
import { Router, NavigationEnd } from '@angular/router';
import { setTheme } from 'ngx-bootstrap/utils';

// External Variables
import { externalVariables } from '@env/external-variables';

// Services
import { AlertService } from '@app/core/services/alert.service';

// 3rd party
import { OrientationInfoRx, UserAgentInfoRx } from '@viablelogic/ngx-responsive';
import { Angulartics2GoogleAnalytics } from 'angulartics2';

// Environment
import { environment } from '@env/environment';

// Utilities
import { Utilities } from '@app/shared/utilities';


@Component({
	selector     : 'app-root',
	templateUrl  : './app.component.html',
	styleUrls    : ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

	orderId                : number;
	supplierId             : number;
	googleAnalyticsInterval: any;


	@HostListener('window:resize', ['$event']) onResize(event) {
		// When body is resized
		this.setResponsiveClasses( event.target.innerWidth );
	}


	constructor(
		private renderer                   : Renderer2,
		private alertService               : AlertService,
		private orientationInfo            : OrientationInfoRx,
		private userAgentInfo              : UserAgentInfoRx,
		private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
		private router                     : Router,
		private utils                      : Utilities
	) {
		// Set theme to Bootstrap 5 for ngx-bootstrap
		setTheme('bs5');

		// Have to load it here otherwise doesn't work on production builds
		this.router.events.subscribe( ( event ) => {
			if ( event instanceof NavigationEnd ) {
				this.changeOfRoutes();
			}
		});

		// Enable Google Analytics tracking
		angulartics2GoogleAnalytics.startTracking();

		// Setup Google Analytics
		this.setupGoogleAnalytics();

		if ( externalVariables?.appVersion ) {
			const appVersion = externalVariables?.appVersion || null;
			console.log(`%cApp booted successfully. %cv${appVersion}`, 'color: #00A2C1', 'color: #42AD65');
		}
	}


	ngOnInit() {
		// Connect with orientation detection
		this.orientationInfo.connect().subscribe(
			(data) => this.orientation( data )
		);

		// Connect with userAgent detection
		this.userAgentInfo.connect().subscribe(
			(data) => this.userAgent( data )
		);
	}


	ngOnDestroy() {
		this.orientationInfo.disconnect();
		this.userAgentInfo.disconnect();

		if ( this.googleAnalyticsInterval ) {
			clearInterval( this.googleAnalyticsInterval );
		}
	}


	ngAfterViewInit() {
		// Set appropriate responsive classes
		setTimeout( () => {
			this.setResponsiveClasses();
		}, 10 );
	}


	// Called on each route change
	changeOfRoutes() { }


	// Setup Google Analytics - this is done here because environment variables not allowed in index.html file
	private setupGoogleAnalytics(): void {
		try {
			// Wait for Google Analytics to boot (max 45 seconds)
			const timeout  = setTimeout(() => clearInterval( this.googleAnalyticsInterval ), 45000);
			this.googleAnalyticsInterval = setInterval(() => {
				if ( typeof (window as any).ga !== 'undefined'  ) {

					clearInterval( this.googleAnalyticsInterval );
					clearTimeout( timeout );

					// Set to false so its ignored in NgOnDestoy
					this.googleAnalyticsInterval = false;

					// Create Analytics instance
					(window as any).ga('create', environment.googleAnalytics, 'auto');
					(window as any).ga('send', 'pageview');	// Send first impression
				}
			}, 1000);
		} catch ( e ) {
			this.utils.logError( e );
		}
	}


	private setResponsiveClasses( calcWidth = 0 ): void {
		let width = calcWidth;
		if ( !calcWidth ) {
			width = this.widthWithScrollbar();
		}

		// w_a_medium means ahead of medium - used for one-time responsive definitions
		const newClass = width < 576 ? 'w_xsmall' :
			width < 768 ? 'w_a_xsmall w_small' :
				width < 992 ? 'w_a_xsmall w_a_small w_medium' :
					width < 1200 ? 'w_a_xsmall w_a_small w_a_medium w_large' : 'w_a_xsmall w_a_small w_a_medium w_large';

		const newClasses = newClass.split(' ');		// Array of new classes
		const allClasses = ['w_xsmall', 'w_a_xsmall', 'w_small', 'w_a_small', 'w_medium', 'w_a_medium', 'w_large'];
		const bodyTag    = document.querySelectorAll('body')[0];

		// Remove all responsive classes
		for ( const _class of allClasses ) {
			if ( _class && bodyTag ) {
				this.renderer.removeClass( bodyTag, _class );
			}
		}

		// Add calculated responsive classes
		for ( const _class of newClasses ) {
			if ( _class && bodyTag ) {
				this.renderer.addClass( bodyTag, _class );
			}
		}
	}


	private orientation( value: string ): void {
		let orientation = '';

		switch ( value ) {
			case 'portrait':
			case 'landscape':
				orientation = value;
				break;
		}

		const bodyTag = document.querySelectorAll('body')[0];

		if ( bodyTag ) {
			// First remove orientation classes
			this.renderer.removeClass( bodyTag, 'landscape' );
			this.renderer.removeClass( bodyTag, 'portrait' );

			// Then Set orientation class in body tag
			this.renderer.addClass( bodyTag, orientation );
		}
	}


	private userAgent( value: any ): void {
		const classes = [];
		const browser = value.browser;
		const ratio   = value.pixelratio;
		let device    = 'unknown-device';

		switch ( value.device ) {
			case 'smarttv':
			case 'desktop':
			case 'tablet':
			case 'mobile':
				device = value.device;
				break;
		}

		if ( browser ) {
			classes.push( browser );
		}

		if ( ratio ) {
			classes.push( ratio );
		}

		if ( device ) {
			classes.push( device );
		}

		// Loop each class array item and add it in body tag
		for ( const classValue of classes ) {
			this.renderer.addClass( document.querySelectorAll('body')[0], classValue );
		}
	}


	private widthWithScrollbar(): number {
		const ele = document.body;
		ele.style.overflow = 'scroll';
		// let widthWithScrollBars = document.documentElement.clientWidth;
		ele.style.overflow = 'hidden';
		const widthNoScrollBars = document.documentElement.clientWidth;
		// let scrollbarWidth = widthWithScrollBars - widthNoScrollBars;
		ele.style.overflow = '';
		return widthNoScrollBars;
	}

}
