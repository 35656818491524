import { Injectable, OnDestroy } from '@angular/core';

import { StorageService } from '@app/core/services/storage.service';


@Injectable({
	providedIn: 'root',
})
export class SessionStorageService extends StorageService implements OnDestroy {
	private intervalRef;
	protected override storageName = 'SessionStorage';


	constructor() {
		super();
		this.intervalRef = setInterval( () => { this.purgeAllExpired(); }, 20000 );
	}


	getKeys(): string[] {
		return this.getAllKeys();
	}


	protected getAllKeys(): string[] {
		if ( sessionStorage ) {
			return Object.keys( sessionStorage );
		} else {
			return Object.keys( window.sessionStorage );
		}
	}


	protected setValue( key: string, value: string ): void {
		try {
			if ( sessionStorage ) {
				sessionStorage.setItem( key, value );
			} else {
				window.sessionStorage.setItem( key, value );
			}
		} catch ( e ) {
			this.utils.logError( e );
			console.log(`${this.storageName} Exception: `, e);
		}
	}


	protected getValue( key: string ): string {
		try {
			if ( sessionStorage ) {
				return sessionStorage.getItem( key );
			} else {
				return window.sessionStorage.getItem( key );
			}
		} catch ( e ) {
			this.utils.logError( e );
			console.log(`${this.storageName} Exception: `, e);
		}

		return null;
	}


	protected removeValue( key: string ): void {
		try {
			if ( sessionStorage ) {
				sessionStorage.removeItem( key );
			} else {
				window.sessionStorage.removeItem( key );
			}
		} catch ( e ) {
			this.utils.logError( e );
			console.log(`${this.storageName} Exception: `, e);
		}
	}


	ngOnDestroy() {
		if ( this.intervalRef ) {
			clearInterval( this.intervalRef );
		}
	}

}
